import { store } from '../index';
import { setTokenList, setTokenSelected, refreshTokenTable, setExportingTokens, setExportEmptyTokens, setDevelopersList, setImportSuccessTokens, setImportSuccessTokensInfo, setExportMissingTokens, addErrorMessage } from '../utils/Reducer';
import { setDetailOp } from '../utils/Reducer';
import { gError, webAPIUser, webAPIPass } from '../utils/BaseRest';
import { exportImportedTokensXLSXFile, exportXLSXFile, utilsApps, whatLangs, exportXLSXZip, exportTRXZip, exportMultipleTRXZip, exportEvolisXLSX, allLangs, exportXLSX, exportDevDoneXLSXZip, exportJSONZip, exportMultipleJSONZip } from '../utils/Utils';
import axios from 'axios';


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getTokens
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getTokens(dev, state, appId, from, limit, urlQS = null, sort = null) {

  const onError = error => {
    gError('getTokens', error);
    store.dispatch(setTokenList({ count: 0, items: [] }));
  }



  store.dispatch(setTokenList(undefined));
  var authToken = store.getState().authToken;
  var search = store.getState().tokenSearch;

  if (urlQS !== "" && urlQS !== null) {
    const urlParams = new URLSearchParams(urlQS);
    const urlFilterDev = urlParams.get('dev');
    const urlFilterState = urlParams.get('state');
    const urlFilterAppId = urlParams.get('application');

    if (urlFilterDev !== null) {
      dev = urlFilterDev;
    }
    if (urlFilterState !== null) {
      state = urlFilterState;
    }
    if (urlFilterAppId !== null) {
      appId = urlFilterAppId;
    }
  }

  search = encodeURI(search);
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/tokens?dev=' + dev + '&state=' + state + '&appid=' + appId + '&from=' + from + '&limit=' + limit + (search === '' ? '' : ('&search=' + search)) + (sort === null ? '' : ('&sort=' + sort)) + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json'
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          store.dispatch(setTokenList(json));
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getToken
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getToken() {

  const onError = error => {
    gError('getToken', error);
    store.dispatch(setDetailOp(undefined));
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/tokens/' + store.getState().detailValue + '?authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json'
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          var apps = utilsApps.map(item => {
            var checked = false;
            for (var i = 0; i < json.apps.length; i++) {
              if (json.apps[i].app_id === item.appId) {
                checked = true;
                break;
              }
            }
            return { appId: item.appId, description: item.description, checked: checked };
          });
          var translators = json.translators.map(item => {
            var checked = false;
            if (json.translator.length > 0) {
              for (var i = 0; i < json.translators.length; i++) {
                if (item.email === json.translator[0].translator_email) {
                  checked = true;
                  break;
                }
              }
            }
            return { email: item.email, checked: checked };
          });
          var token = json;
          token.apps = apps;
          token.translators = translators;
          store.dispatch(setTokenSelected(token));
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getDevelopers
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getDevelopers() {

  const onError = error => {
    gError('getDevelopers', error);
    store.dispatch(setDevelopersList(undefined));
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/developers?authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json'
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          store.dispatch(setDevelopersList(json));
          return;
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// createToken
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function createToken(token, file = null) {

  const onError = error => {
    gError('createToken', error);
    store.dispatch(setDetailOp(undefined));
  }

  token.apps = token.apps.filter(app => app.checked);
  token.translator = token.translators.filter(translator => translator.checked);
  token.langs = whatLangs(token);
  token.apps = token.apps.map(app => app.appId);
  if (token.translator.length > 0)
    token.translator = token.translator.map(translator => translator.email);
  else
    token.translator = [];

  var authToken = store.getState().authToken;

  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/tokens/create?authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(token)
  })
    .then(response => {

      if (response.ok) {
        response.json().then(json => {
          if (typeof json.result_message != 'undefined') {
            store.dispatch(addErrorMessage(json.result_message));
          } else {

            if (file !== null) {
              var data = new FormData()
              data.append('screenshoot', file, file.name);
              axios({
                method: 'put',
                url: process.env.REACT_APP_WEB_API_URL + '/v1/tokens/' + json.token_id + '/screenshot?authToken=' + authToken,
                data: data,
                headers: {
                  'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
                  'Content-Type': 'multipart/form-data'
                }
              })
                .then(function (response) {
                })
                .catch(function (response) {
                  console.log(response);
                });
            }
            store.dispatch(refreshTokenTable());

          }
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setToken
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setToken(token, file = null) {

  const onError = error => {
    gError('setToken', error);
  }

  token.apps = token.apps.filter(app => app.checked);
  token.translator = token.translators.filter(translator => translator.checked);
  token.langs = whatLangs(token);
  token.apps = token.apps.map(app => app.appId);
  if (token.translator.length > 0)
    token.translator = token.translator.map(translator => translator.email);
  else
    token.translator = [];

  var authToken = store.getState().authToken;

  const tokenID = store.getState().detailValue;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/tokens/' + store.getState().detailValue + '?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(token)
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {


          if (typeof json.result_message != 'undefined') {
            store.dispatch(addErrorMessage(json.result_message));
          } else {

            if (file !== null) {
              var data = new FormData()
              data.append('screenshoot', file, file.name);
              axios({
                method: 'put',
                url: process.env.REACT_APP_WEB_API_URL + '/v1/tokens/' + tokenID + '/screenshot?authToken=' + authToken,
                data: data,
                headers: {
                  'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
                  'Content-Type': 'multipart/form-data'
                }
              })
                .then(function (response) {
                })
                .catch(function (response) {
                  console.log(response);
                });
            }

            store.dispatch(refreshTokenTable());
          }
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setTokenState
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setTokenState(token_id, state) {

  const onError = error => {
    gError('setTokenState', error);
    store.dispatch(setDetailOp(undefined));
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/tokens/' + token_id + '/state?state=' + state + '&authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(refreshTokenTable());
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteToken
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteToken(token) {

  const onError = error => {
    gError('deleteToken', error);
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/tokens/' + token.token_id + '?authToken=' + authToken, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
    },
  })
    .then(response => {
      if (response.ok) {
        store.dispatch(refreshTokenTable());
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setTokenTranslations
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setTokenTranslations(translations, submit) {

  const onError = error => {
    gError('setTokenTranslations', error);
  }

  var authToken = store.getState().authToken;
  var token_id = store.getState().detailValue;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/tokens/' + store.getState().detailValue + '/translations?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(translations)
  })
    .then(response => {
      if (response.ok) {
        if (submit === 1) {
          setTokenState(token_id, "SUBMITED/PT");
        }
        else if (submit === 2) {
          setTokenState(token_id, "SUBMITED/T");
        }

        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setTokenTranslator
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setTokenTranslator(translators) {

  const onError = error => {
    gError('setTokenTranslator', error);
  }
  var email = '';
  for (var x = 0; x < translators.trans.length; x++) {
    if (translators.trans[x].checked === true) {
      email = translators.trans[x].email;
      break;
    }
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/tokens/' + email + '/translators?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(translators.trans[0].tokenList)
  })
    .then(response => {
      if (response.ok) {
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// exportEvolisXLSXTokens
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function exportEvolisXLSXTokens(langs, apps) {

  const onError = error => {
    gError('exportEvolisXLSXTokens', error);
    store.dispatch(setDetailOp(undefined));
  }

  store.dispatch(setExportingTokens(true));

  var appList = [];
  var langsList = [];

  for (var x = 0; x < langs.length; x++) {
    if (langs[x].checked)
      langsList.push(langs[x].lang)
  }

  if (langsList.length === 0)
    langsList = allLangs();

  for (var i = 0; i < apps.length; i++) {
    if (apps[i].checked)
      appList.push(apps[i].appId);
  }
  if (appList.length === 0)
    appList.push(1, 4, 5);

  var newObj = { apps: appList, langs: langsList }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/export/evolisxlsx?&authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newObj)
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {

          exportEvolisXLSX(json.tokens, json.translations, langsList);

          store.dispatch(setExportingTokens(false));

        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// exportTranslatorXLSXTokens
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function exportTranslatorXLSXTokens(tokenIDs = null) {

  const onError = error => {
    gError('exportTranslatorXLSXTokens', error);
    store.dispatch(setDetailOp(undefined));
  }

  store.dispatch(setExportingTokens(true));

  var data = { tokenIDs: tokenIDs };

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/export/translatorxlsx?&authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {

          var langsArray = [];
          for (var l = 0; l < json.langs.length; l++) {
            langsArray.push(json.langs[l].lang);
          }
          exportXLSX(json.tokens, langsArray);
          store.dispatch(setExportingTokens(false));

        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// exportDevDone
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function exportDevDone(apps) {

  const onError = error => {
    gError('exportDevDone', error);
    store.dispatch(setDetailOp(undefined));
  }

  store.dispatch(setExportingTokens(true));
  var appList = [];
  for (var i = 0; i < apps.length; i++) {
    if (apps[i].checked)
      appList.push(apps[i].appId);
  }
  if (appList.length === 0)
    appList.push(0);

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/export/devdonexlsx?&authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(appList)
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          if (json.length > 1) {
            exportDevDoneXLSXZip(json);
          }
          else {
            exportXLSX(json[0], allLangs());
          }
          for (var j = 0; j < json.length; j++) {
            if (json[j].length < 1) {
              store.dispatch(setExportEmptyTokens(true));
              break;
            }
          }
          store.dispatch(setExportingTokens(false));

        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// exportXLSXTokens
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function exportXLSXTokens(int, langs, apps) {

  const onError = error => {
    gError('exportXLSXTokens', error);
    store.dispatch(setDetailOp(undefined));
  }
  store.dispatch(setExportingTokens(true));

  var hasApp = false;
  var appList = [[0], [1], [2], [3], [4], [5]];
  var langsList = [];

  for (var x = 0; x < langs.length; x++) {
    if (langs[x].checked)
      langsList.push(langs[x].lang)
  }

  if (langsList.length === 0)
    langsList = allLangs();

  for (var i = 0; i < apps.length; i++) {
    if (apps[i].checked) {
      appList[apps[i].appId].push(langsList);
      hasApp = true;
    }
  }
  if (!hasApp) {
    appList[0].push(langsList)
  }
  var newObj = { apps: appList };

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/export/xlsx?int=' + int + '&authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newObj)
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          var newJson = { tokens: [], translations: [] };
          var versions = [];
          for (var i = 0; i < json.tokens.length; i++) {
            if (json.tokens[i].length > 1) {
              newJson.tokens.push(json.tokens[i]);
              newJson.translations.push(json.translations[i]);
              versions.push(json.versions[i]);
            }
          }
          if (newJson.tokens.length > 1)
            exportXLSXZip(newJson.tokens, newJson.translations, versions, langsList);
          else
            exportXLSXFile(newJson.tokens[0], newJson.translations[0], versions[0], langsList);
          store.dispatch(setExportingTokens(false));
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// exportXLSXTokensProduction
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export async function exportXLSXTokensProduction(apps, exportType) {

  const onError = error => {
    gError('exportXLSXTokens', error);
    store.dispatch(setDetailOp(undefined));
  }
  store.dispatch(setExportingTokens(true));

  var hasApp = false;
  var appList = [[0], [1], [2], [3], [4], [5]];
  var langsList = [];

  langsList = allLangs();

  for (var i = 0; i < apps.length; i++) {
    if (apps[i].checked) {
      appList[apps[i].appId].push(langsList);
      hasApp = true;
    }
  }
  if (!hasApp) {
    appList[3].push(langsList)
  }
  var newObj = { apps: appList };

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/export/xlsxProduction?int=' + exportType + '&authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newObj)
  })
    .then(response => {

      if (response.ok) {
        response.json().then(json => {
          (async () => {
            var newJson = { tokens: [], translations: [] };
            var versions = [];
            for (var i = 0; i < json.tokens.length; i++) {
              if (json.tokens[i].length > 1) {
                newJson.tokens.push(json.tokens[i]);
                newJson.translations.push(json.translations[i]);
                versions.push(json.versions[i]);
              }
            }
            if (newJson.tokens.length > 1) {
              await exportXLSXZip(newJson.tokens, newJson.translations, versions, langsList, true);
            } else {
              await exportXLSXFile(newJson.tokens[0], newJson.translations[0], versions[0], langsList, true);
            }
            store.dispatch(setExportingTokens(false));
            return;
          })();
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// exportTRXTokens
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function exportTRXTokens(int, langs, apps) {

  const onError = error => {
    gError('exportTRXTokens', error);
    store.dispatch(setDetailOp(undefined));
  }
  store.dispatch(setExportingTokens(true));
  var hasApp = false;
  var appList = [[0], [1], [2], [3], [4], [5], [6]];
  for (var i = 0; i < apps.length; i++) {
    if (apps[i].checked) {
      appList[apps[i].appId].push(utilsApps[apps[i].appId - 1].langs);
      hasApp = true;
    }
  }
  if (!hasApp) {
    appList[0].push(langs)
  }
  var newObj = { apps: appList };

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/export/trx?int=' + int + '&authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newObj)
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          var newJson = [];
          var versions = [];
          for (var i = 0; i < json.tokens.length; i++) {
            if (json.tokens[i].length > 1) {
              newJson.push(json.tokens[i]);
              versions.push(json.versions[i]);
            }
          }
          if (newJson.length > 1)
            exportMultipleTRXZip(newJson, versions);
          else
            exportTRXZip(newJson[0], versions[0]);
          store.dispatch(setExportingTokens(false));
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// exportJSONTokens
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function exportJSONTokens(int, langs, apps) {

  const onError = error => {
    gError('exportTRXTokens', error);
    store.dispatch(setDetailOp(undefined));
  }
  store.dispatch(setExportingTokens(true));
  var hasApp = false;
  var appList = [[0], [1], [2], [3], [4], [5], [6]];
  for (var i = 0; i < apps.length; i++) {
    if (apps[i].checked) {
      appList[apps[i].appId].push(utilsApps[apps[i].appId - 1].langs);
      hasApp = true;
    }
  }
  if (!hasApp) {
    appList[0].push(langs)
  }
  var newObj = { apps: appList };

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/export/json?int=' + int + '&authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newObj)
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          var newJson = [];
          var versions = [];
          for (var i = 0; i < json.tokens.length; i++) {
            if (json.tokens[i].length > 1) {
              newJson.push(json.tokens[i]);
              versions.push(json.versions[i]);
            }
          }
          if (newJson.length > 1)
            exportMultipleJSONZip(newJson, versions);
          else
            exportJSONZip(newJson[0], versions[0]);
          store.dispatch(setExportingTokens(false));
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// exportTRXTokensProduction
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export async function exportTRXTokensProduction(apps, exportType) {

  const onError = error => {
    gError('exportTRXTokens', error);
    store.dispatch(setDetailOp(undefined));
  }
  store.dispatch(setExportingTokens(true));
  var hasApp = false;
  var appList = [[0], [1], [2], [3], [4], [5]];
  for (var i = 0; i < apps.length; i++) {
    if (apps[i].checked) {
      appList[apps[i].appId].push(utilsApps[apps[i].appId - 1].langs);
      hasApp = true;
    }
  }
  if (!hasApp) {
    appList[0].push(allLangs())
  }
  var newObj = { apps: appList };

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/export/trxProduction?int=' + exportType + '&authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newObj)
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          var newJson = [];
          var versions = [];
          for (var i = 0; i < json.tokens.length; i++) {
            if (json.tokens[i].length > 1) {
              newJson.push(json.tokens[i]);
              versions.push(json.versions[i]);
            }
          }
          if (newJson.length > 1) {
            exportMultipleTRXZip(newJson, versions, true);
          } else {
            exportTRXZip(newJson[0], versions[0], true);
          }
          store.dispatch(setExportingTokens(false));
          return;
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// importXLSXTokens
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function importXLSXTokens(file) {

  const onError = error => {
    gError('importXLSXTokens', error);
    store.dispatch(setDetailOp(undefined));
  }

  var fd = new FormData();
  fd.append('file', file);

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/tokens/import?&authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
    },
    body: fd
  })
    .then(response => {
      if (response.ok) {

        response.json().then(json => {

          if (json.tokensNotFoundData.length > 0) {
            store.dispatch(setExportMissingTokens(true));
            exportImportedTokensXLSXFile(json.tokensNotFoundData);
          }
          let messages = [];
          messages.push(["Ok:", json.tokensOkCount]);
          messages.push(["Nok: ", json.tokensNokCount]);
          messages.push(["Not found: ", json.tokensNotFoundCount]);
          messages.push(["Tokens with error: ", json.tokensWithError]);
          messages.push(["Tokens not found: ", json.tokensNotFound]);
          store.dispatch(setImportSuccessTokensInfo(messages));
          store.dispatch(setImportSuccessTokens(true));

        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// undoFromVerified
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function undoFromVerified(id) {

  const onError = error => {
    gError('undoFromVerified', error);
    store.dispatch();
  }

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/tokens/' + id + '?authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json'
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(json => {
          var allTranslated = true;
          for (var i = 0; i < json.translations.length; i++) {
            if (json.translations[i].trans_state === "UNTRANSLATED") {
              setTokenState(id, "SUBMITED/PT");
              allTranslated = false;
              break;
            }
          }
          if (allTranslated) {
            setTokenState(id, "SUBMITED/T");
          }
        })
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}

//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// saveBackup
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function saveBackup(file, name, version) {

  const onError = error => {
    gError('saveBackup', error);
  }

  var fd = new FormData();
  fd.append('file', file);

  var authToken = store.getState().authToken;
  fetch(process.env.REACT_APP_WEB_API_URL + '/v1/export/saveBackup?name=' + name + '&version=' + version + '&authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization': 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept': 'application/json',
    },
    body: fd
  })
    .then(response => {
      if (response.ok) {
        return;
      }
      response.json().then(json => onError(json.message));
    })
    .catch(error => onError(error.message));
}