import React, { useState, useEffect, useRef } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { clearErrorMessageList, setDetailOp, setTabIndex, logout, setChangePass } from './utils/Reducer';
import { gStyles } from './utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Container, AppBar, Toolbar, Typography, Tabs, Tab, Snackbar, Box, IconButton, Menu, MenuItem, Divider } from '@material-ui/core';
import { Person, ArrowDropDown } from '@material-ui/icons';

import SignIn from './login/SignIn';
import ChangePassword from './login/ChangePassword';
import TokenTable from './tokens/TokenTable';
import TokenDetail from './tokens/TokenDetail';
import TranslatorTable from './translators/TranslatorTable';
import TranslatorDetail from './translators/TranslatorDetail';

import logo from './resources/logoCPpt.png';
import topbanner from './resources/topbanner.png';
import customersIcon from './resources/customersIcon.png';
import tokensIcon from './resources/tokensIcon.png';
import { checkUser } from './utils/Utils';

let strings=new LocalizedStrings({
	en:{
    tabTokens:'Tokens',
    tabTranslators:'Translators',

    menuChangePass:'Change password',
    menuLogout:'Logout',
	}
})


function App( props ) {

  const { classes } = props;

  const { userType } = props;

  const { authToken, needPassword, userEmail } = props;
  const { changePass, setChangePass } = props;
  const { tabIndex, tabIndexName, setTabIndex } = props;
  const { logout } = props;
  const { errorMessageList, clearErrorMessageList } = props;
  const { detailOpen, setDetailOp } = props;

  const [ userMenuEl, setUserMenuEl ] = React.useState(null);
  
  const [sticky, setSticky] = useState(false);
  const [navHeight, setNavHeight] = useState(0);

  // All refs
  const navbar = useRef(); 
  const navbarOffset = useRef();

  // Mount the Event Listener on loading the site
  useEffect(() => { 
    const handleScroll = () => {
      
      // Get the offset to the top
      const value = navbarOffset.current.clientHeight;
      const effectMargin = navbar.current.clientHeight/2;

      // Set the boolean value   
      setSticky(window.pageYOffset >= (value+effectMargin));
    } 

    window.addEventListener('scroll', handleScroll);
    
    // Set the height of the Navbar    
    if (typeof navbar.current !== 'undefined' && typeof navbar.current.clientHeight !== 'undefined'){
      setNavHeight(navbar.current.clientHeight);
    }

    // Remove the listener when cleaning up
    return () => window.removeEventListener('scroll', handleScroll);

  }, []);

  const handleTabChange = ( event, newValue ) => {
    setTabIndex( newValue );
    setDetailOp( undefined );
  }

  const handleUserMenuClick = event => {
    setUserMenuEl( event.currentTarget );
  };

  const handleUserMenuClose = () => {
    setUserMenuEl( null );
  };

  const handleLogout = () => {
    handleUserMenuClose( );
    logout();
  }

  const handleChangePass = () => {
    handleUserMenuClose( );
    setChangePass( true );
  }

  return (
    <Container className={ classes.app } maxWidth='xl' style={ (sticky ? ( {marginBottom: 100} ) : ( {} )) } >
      { !authToken &&
        <SignIn/>
      }
      { authToken && ( needPassword || changePass ) &&
        <ChangePassword/>
      }
      { authToken && !( needPassword || changePass ) &&
        <div>  
          <div style={({ marginTop: navHeight })}></div>
          <div ref={navbarOffset}>
            <AppBar ref={navbar} className={ classes.appBar } position="fixed">
              <Toolbar className={ classes.menuToolbar }>   
              <img className={ classes.logoImg } src={ logo } alt=''/>
                <Typography variant='h6'> { strings.title } </Typography> 
                <Tabs className={ classes.flexExpand } value={ tabIndex } onChange={ handleTabChange }>
                  <Tab label={ (sticky ? ('') : (strings.tabTokens)) } icon={ <img src={ tokensIcon } alt=''/> }/>
                  { checkUser(userType)==='ADMIN' && 
                  <Tab label={ (sticky ? ('') : (strings.tabTranslators)) } icon={ <img src={ customersIcon } alt=''/> }/>
                  }
                </Tabs> 
                <Box className={ classes.appUserBox }>
                  <Person className={ classes.appUserItem } fontSize='large'/>
                  <Typography className={ classes.appUserItem } style={ (sticky ? ( {display: 'none'} ) : ( {display: 'block'} )) } variant='caption'>{ userEmail }</Typography>
                </Box>
                <IconButton color='inherit' onClick={ handleUserMenuClick }><ArrowDropDown/></IconButton>
                <Menu
                  getContentAnchorEl={ null }
                  anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
                  transformOrigin={ { vertical: 'top', horizontal: 'left' } }
                  anchorEl={ userMenuEl }
                  keepMounted
                  open={ Boolean(userMenuEl) }
                  onClose={ handleUserMenuClose } >
                  <MenuItem onClick={ handleChangePass }><Typography variant='body1'>{ strings.menuChangePass }</Typography></MenuItem>
                  <Divider/>
                  <MenuItem onClick={ handleLogout }><Typography variant='body1'>{ strings.menuLogout }</Typography></MenuItem>
                </Menu>
              </Toolbar>
            </AppBar>
          </div>
          <img className={ classes.topBanner } src={ topbanner } alt=''/>
          { tabIndexName === 'token' && detailOpen === undefined && <TokenTable/> }
          { tabIndexName === 'translator' && detailOpen === undefined && <TranslatorTable/> }
          { detailOpen === 'token' && <TokenDetail/> }
          { detailOpen === 'translator' && <TranslatorDetail/> }

        </div>
      }
      <Snackbar
        anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
        open={ errorMessageList.length > 0 }
        message={ errorMessageList.map( ( item, index) => {
          return (
            <Typography key={ index } variant='body1' color='inherit'>{ item }</Typography>
          )
        } ) }
        autoHideDuration={ 3000 }
        onClose={ clearErrorMessageList }
      />
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    authToken: state.authToken,
    userType: state.userType,
    userEmail: state.userEmail,
    needPassword: state.needPassword,
    changePass: state.changePass,
    tabIndex: state.tabIndex,
    tabIndexName: state.tabIndexName,
    errorMessageList: state.errorMessageList,
    detailOpen: state.detailOpen,
  }
};

export default connect( mapStateToProps, { clearErrorMessageList, setDetailOp, setTabIndex, logout, setChangePass } )( withStyles( gStyles )( App ) );
