import LocalizedStrings from 'react-localization';
import { store } from '../index';
import { addErrorMessage } from './Reducer';

const strings = new LocalizedStrings( {
	en:{
    unknownError: 'Unknown error',
    sessionExpiredError: 'Session expired',
    serverInternalError: 'Server internal error',
    serverIsDown: 'Server is down',
  }
} )

const translateError = error => {
  switch ( error ) {
    case 'SERVER_INTERNAL_ERROR': return strings.serverInternalError;
    case 'LOGIN_NOK': return strings.sessionExpiredError;
    case 'Failed to fetch': return strings.serverIsDown;
    default: return strings.unknownError;
  }
}

export const webAPIUser = 'cpwebapi';
export const webAPIPass = 'ZVE8rh2DWJPQEWar';

export const gError = ( func, error ) => {
  console.error( func + '.OnError: ' + error );
  store.dispatch( addErrorMessage( translateError( error ) ) );
}
