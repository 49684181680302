import JSZip from 'jszip';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import ExcelJs from 'exceljs';
import { saveBackup } from '../tokens/TokenRest';

export var newLangs = [
    { description: 'English', lang: 'en' },
    { description: 'العربية (Arabic)', lang: 'ar' },
    { description: 'česky (Czech)', lang: 'cs' },
    { description: 'Dansk (Danish)', lang: 'da' },
    { description: 'Nederlands (Dutch)', lang: 'nl' },
    { description: 'Suomi (Finnish)', lang: 'fi' },
    { description: 'Français (French)', lang: 'fr' },
    { description: 'Deutsch (German)', lang: 'de' },
    { description: 'Magyar (Hungarian)', lang: 'hu' },
    { description: 'Indonesia (Indonesian)', lang: 'id' },
    { description: 'Italiano (Italian)', lang: 'it' },
    { description: '日本語 (Japanese)', lang: 'ja' },
    { description: '한국어 (Korean)', lang: 'ko' },
    { description: 'Norsk (Norwegian)', lang: 'no' },
    { description: 'Polski (Polish)', lang: 'pl' },
    { description: 'Português (Portuguese)', lang: 'pt' },
    { description: 'Русский (Russian)', lang: 'ru' },
    { description: 'Español (Spanish)', lang: 'es' },
    { description: 'Svenska (Swedish)', lang: 'sv' },
    { description: '简体中文 (Simplified Chinese)', lang: 'zhcn' },
    { description: '繁體中文 (Traditional Chinese)', lang: 'zhtw' },
    { description: 'Türkçe (Turkish)', lang: 'tr' },
    { description: 'עברית (Hebrew)', lang: 'he' },
    { description: 'नेपाली (Nepali)', lang: 'ne' },
    { description: 'فارسی (Persian)', lang: 'fa' },
    { description: 'ဗမာ စကာ (Burmese)', lang: 'my' },
    { description: 'Slovenčina (Slovak)', lang: 'sk' },
    { description: 'ไทย (Thai)', lang: 'th' },
    { description: 'Tiếng Việt (Vietnamese)', lang: 'vi' },
    { description: 'Bosanski (Bosnian)', lang: 'bs' },
    { description: 'Hrvatski (Croatian)', lang: 'hr' },
    { description: 'Сервиан (Serbian)', lang: 'sr' },
    { description: 'Ucraniano (Ukrainian)', lang: 'uk' }
];

/*function getLangId(lang){
    for(var i=0; i<newLangs.length; i++){
        if(lang===newLangs[i].lang){
            return i;
        }
    }
    return false;
}*/

export function getLangDescription(lang) {
    for (var l = 0; l < newLangs.length; l++) {
        if (lang === newLangs[l].lang) {
            return newLangs[l].description;
        }
    }
    return false;
}

export function allLangs() {
    var allLangs = [];
    for (var i = 0; i < newLangs.length; i++) {
        allLangs.push(newLangs[i].lang);
    }
    return allLangs;
}

function getLangIdInArray(langs, lang) {
    for (var l = 0; l < langs.length; l++) {
        if (lang === langs[l]) {
            return l;
        }
    }
    return false;
}

export const utilsApps = [
    { view: [1, 2, 4], appId: 1, label: 'BS2', description: 'Badge Studio', langs: ['en', 'ar', 'cs', 'da', 'nl', 'fi', 'fr', 'de', 'hu', 'id', 'it', 'ja', 'ko', 'no', 'pl', 'pt', 'ru', 'es', 'sv', 'zhcn', 'zhtw', 'tr'] },
    { view: [1, 2], appId: 2, label: 'CP1', description: 'cardPresso 1', langs: ['en', 'ar', 'cs', 'da', 'nl', 'fi', 'fr', 'de', 'he', 'hu', 'id', 'it', 'ja', 'ko', 'ne', 'no', 'fa', 'my', 'pl', 'pt', 'ru', 'sk', 'es', 'sv', 'zhtw', 'zhcn', 'th', 'tr', 'vi', 'bs', 'hr', 'sr', 'uk'] },
    { view: [1, 2], appId: 3, label: 'CP2', description: 'cardPresso 2', langs: ['en', 'ar', 'cs', 'da', 'nl', 'fi', 'fr', 'de', 'he', 'hu', 'id', 'it', 'ja', 'ko', 'ne', 'no', 'fa', 'my', 'pl', 'pt', 'ru', 'sk', 'es', 'sv', 'zhtw', 'zhcn', 'th', 'tr', 'vi', 'uk'] },
    { view: [1, 2, 4], appId: 4, label: 'EDH', description: 'Edikio Guest', langs: ['en', 'ar', 'cs', 'da', 'nl', 'fi', 'fr', 'de', 'hu', 'id', 'it', 'ja', 'ko', 'no', 'pl', 'pt', 'ru', 'es', 'sv', 'zhcn', 'zhtw', 'tr'] },
    { view: [1, 2, 4], appId: 5, label: 'EDK', description: 'Edikio Price Tag', langs: ['en', 'cs', 'da', 'nl', 'fr', 'de', 'it', 'ja', 'no', 'pl', 'pt', 'zhcn', 'es'] },
    { view: [1, 2], appId: 6, label: 'CPM', description: 'CP Mobility', langs: ['en', 'ar', 'cs', 'da', 'nl', 'fi', 'fr', 'de', 'he', 'hu', 'id', 'it', 'ja', 'ko', 'ne', 'no', 'fa', 'my', 'pl', 'pt', 'ru', 'sk', 'es', 'sv', 'zhtw', 'zhcn', 'th', 'tr', 'vi'] },
];

function getAppById(id) {
    return utilsApps[id - 1].description;
}

function getAppLabelById(id) {
    return utilsApps[id - 1].label;
}

export function whatLangs(token) {
    var result = [];

    for (var i = 0; i < token.apps.length; i++) {
        const apppId = token.apps[i].appId;
        var uApp = utilsApps.filter(appp => appp.appId === apppId);

        for (var l = 0; l < uApp[0].langs.length; l++) {
            if (!result.includes(uApp[0].langs[l]))
                result.push(uApp[0].langs[l]);
        }
    }
    return result;
}

var stateTransitions = [
    { current: 'OPEN', next: 'DEV_DONE' },
    { current: 'DEV_DONE', next: 'READY_TO_TRANSLATE' },
    { current: 'READY_TO_TRANSLATE', next: 'PARTIALLY_TRANSLATED' },
    { current: 'PARTIALLY_TRANSLATED', next: 'SUBMITED/PT' },
    { current: 'TRANSLATED', next: 'SUBMITED/T' },
    { current: 'SUBMITED/PT', next: 'PARTIALLY_TRANSLATED' },
    { current: 'SUBMITED/T', next: 'TRANSLATED' },
    { current: 'VERIFIED', next: 'VERIFIED' }
]

export function nextState(currentState) {
    for (var i = 0; i < stateTransitions.length; i++) {
        if (currentState === stateTransitions[i].current) {
            return stateTransitions[i].next;
        }
    }
    return false;
}

export function previousState(currentState) {

    for (var i = 0; i < stateTransitions.length; i++) {
        if (currentState === stateTransitions[i].next) {
            return stateTransitions[i].current;
        }
    }
    return false;

}

var type = [
    { name: 'ADMIN', type: 1 },
    { name: 'DEV', type: 2 },
    { name: 'TRANSLATOR', type: 3 },
    { name: 'EVOLIS', type: 4 }
]

export function checkUser(userType) {
    return type[userType - 1].name;
}

export const tokenStates = [
    {
        name: 'OPEN',
        description: 'Open',
        view: [1, 2],
        operations: [
            { op: 'SHOW', who: [1] },
            { op: 'EDIT_TOKEN', who: [2] },
            { op: 'EDIT_TRANSLATION', who: [2] },
            { op: 'DELETE', who: [2] },
            { op: 'DONE', who: [2], next: 'DEV_DONE' }
        ]
    },
    {
        name: 'DEV_DONE',
        description: 'Developer Done',
        view: [1, 2],
        operations: [
            { op: 'SHOW', who: [2] },
            { op: 'EDIT_TOKEN', who: [1] },
            { op: 'EDIT_TRANSLATION', who: [1] },
            { op: 'UNDO', who: [2], next: 'OPEN' },
            { op: 'SEND_TO_TRANSLATE', who: [1], next: 'READY_TO_TRANSLATE' }
        ]
    },
    {
        name: 'READY_TO_TRANSLATE',
        description: 'Ready to Translate',
        view: [1, 2, 3, 4],
        operations: [
            { op: 'SHOW', who: [1, 2] },
            { op: 'EDIT_TOKEN', who: [3, 4] },
            { op: 'EDIT_TRANSLATION', who: [3, 4] },
            { op: 'UNDO', who: [1], next: 'DEV_DONE' },
            { op: 'SUBMIT', who: [3, 4], next: 'SUBMITED/PT' },
        ]
    },
    {
        name: 'PARTIALLY_TRANSLATED',
        description: 'Partially Translated',
        view: [1, 2, 3, 4],
        operations: [
            { op: 'SHOW', who: [1, 2] },
            { op: 'EDIT_TOKEN', who: [3, 4] },
            { op: 'EDIT_TRANSLATION', who: [3, 4] },
            { op: 'SUBMIT', who: [3, 4], next: 'SUBMITED/PT' },
        ]
    },
    {
        name: 'TRANSLATED',
        description: 'Translated',
        view: [1, 2, 3, 4],
        operations: [
            { op: 'SHOW', who: [2] },
            { op: 'EDIT_TOKEN', who: [1, 3, 4] },
            { op: 'EDIT_TRANSLATION', who: [1, 3, 4] },
            { op: 'DELETE', who: [1] },
            { op: 'SUBMIT', who: [3, 4], next: 'SUBMITED/T' },
        ]
    },
    {
        name: 'SUBMITED/PT',
        description: 'Submited and Partially Translated',
        view: [1, 2, 4],
        operations: [
            { op: 'SHOW', who: [2, 4] },
            { op: 'EDIT_TOKEN', who: [1] },
            { op: 'EDIT_TRANSLATION', who: [1] },
            { op: 'UNDO', who: [1], next: 'PARTIALLY_TRANSLATED' },
            { op: 'VERIFY', who: [1], next: 'VERIFIED' },
            { op: 'DELETE', who: [1] },
        ]
    },
    {
        name: 'SUBMITED/T',
        description: 'Submited and Translated',
        view: [1, 2, 4],
        operations: [
            { op: 'SHOW', who: [2, 4] },
            { op: 'EDIT_TOKEN', who: [1] },
            { op: 'EDIT_TRANSLATION', who: [1] },
            { op: 'DELETE', who: [1] },
            { op: 'UNDO', who: [1], next: 'TRANSLATED' },
            { op: 'VERIFY', who: [1], next: 'VERIFIED' },
        ]
    },
    {
        name: 'VERIFIED',
        description: 'Verified',
        view: [1, 2, 4],
        operations: [
            { op: 'SHOW', who: [1, 2, 4] },
            { op: 'DELETE', who: [1] },
            { op: 'UNDO', who: [1], next: 'SUBMITED/T' },
        ]
    },
]

export function canITransation(operation, userType, state) {
    try {
        return tokenStates.filter(tokenState => (tokenState.name === state))[0]
            .operations.filter(operations => operations.op === operation)[0]
            .who.includes(userType);
    }
    catch (exception) {
        return false;
    }
}

export function transationNextState(operation, state) {
    return tokenStates.filter(tokenState => (tokenState.name === state))[0]
        .operations.filter(operations => operations.op === operation)[0]
        .next;
}


var operations = [
    { operation: 'ADD_TOKEN', allow: [2] },
    { operation: 'ADD_TRANSLATOR', allow: [1] },
    { operation: 'APP_LIST', allow: [1, 2, 4] },
    { operation: 'STATE_LIST', allow: [1, 2, 3, 4] },
    { operation: 'DEV_LIST', allow: [1, 2] },
    { operation: 'PREVIOUS_STATE', allow: [1] },
    { operation: 'NEXT_STATE', allow: [1] },
    { operation: 'EDIT_TRANSLATION', allow: [2, 3, 4] },
    { operation: 'TRANSLATED', allow: [1] },
    { operation: 'UNTRANSLATE', allow: [3, 4] },
    { operation: 'SUBMIT', allow: [3, 4] },
    { operation: 'VERIFY', allow: [1] },
    { operation: 'DUPLICATE', allow: [2] },
    { operation: 'EXPORT_EVOLIS', allow: [4] },
    { operation: 'EXPORT_TRANSLATOR', allow: [3] },
    { operation: 'EXPORT_TRANSLATOR_PARTIAL', allow: [3] },
    { operation: 'EXPORT', allow: [1] },
    { operation: 'IMPORT', allow: [1, 4] },
    { operation: 'IMPORT_TRANSLATOR', allow: [3] },
    { operation: 'TOKENS_READY', allow: [2] },
    { operation: 'TOKENS_DELETE', allow: [2] },
    { operation: 'TOKENS_UNDO_TO_EDIT', allow: [2] },
];

export function canIButton(operation, userType) {
    for (var i = 0; i < operations.length; i++) {
        if (operation === operations[i].operation) {
            return operations[i].allow.includes(userType);
        }
    }
    return false;
}

export function exportDevDoneXLSXZip(tokens) {
    var zip = new JSZip();
    //var blobs = [];
    for (var i = 0; i < tokens.length; i++) {
        if (tokens[i].length > 0) {
            var xlsx3 = convertToXLSXDevDoneAndTranslator(tokens[i], allLangs());
            var worksheet = XLSX.utils.aoa_to_sheet(xlsx3);
            //var worksheet1 = ExcelJs.;

            var new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");

            /* generate array buffer */
            var wbout = XLSX.write(new_workbook, { type: "array", bookType: 'xlsx', password: '123' });
            //var blob = new Blob([wbout], { type: 'application/octet-stream' });
            // blobs.push( blob );
            if (tokens[i][0].app_id !== undefined)
                zip.file(getAppById(tokens[i][0].app_id) + '.xlsx', wbout, { binary: true });
            else
                zip.file("empty.xlsx", wbout, { binary: true });
        }
    }
    var numberOfEntries = Object.keys(zip.files).length;
    if (numberOfEntries > 0) {
        zip.generateAsync({ type: "blob" }).then(function (content) {
            // see FileSaver.js
            FileSaver.saveAs(content, "xlsx.zip");
        });
    }
}

export async function exportEvolisXLSX(tokens, translations, langs) {

    if (tokens.length > 0) {
        var xlsx3 = convertToXLSXEvolis(tokens, translations, langs);

        const workbook = new ExcelJs.Workbook();
        const worksheet = workbook.addWorksheet("Translations");

        worksheet.addRows(xlsx3);

        for (var col = 0; col < (xlsx3[0].length - 5); col++) {
            var tokenCol = worksheet.getColumn(col + 4);
            tokenCol.protection = { locked: false };
        }

        if (worksheet.getCell('C1').value !== 'en') {
            tokenCol = worksheet.getColumn(3);
            tokenCol.protection = { locked: false };
        }

        var headerRow = worksheet.getRow(1);
        headerRow.protection = { locked: true }

        await worksheet.protect('#editToken#', { formatCells: true, formatColumns: true, formatRows: true });

        headerRow.getCell(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '207345' }
        };

        headerRow.getCell(2).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '207345' }
        };

        headerRow.getCell(headerRow.cellCount).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '207345' }
        };

        headerRow.getCell(headerRow.cellCount - 1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '207345' }
        };

        const buffer = await workbook.xlsx.writeBuffer();


        /*var worksheet = XLSX.utils.aoa_to_sheet(xlsx3);
        var new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");

        /* generate array buffer */
        //var wbout = XLSX.write(new_workbook, {type:"array", bookType:'xlsx'});


        var blob1 = new Blob([buffer], { type: 'application/octet-stream' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob1, "tokens.xlsx");
        }
        else {
            var link1 = document.createElement('a');
            if (link1.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url1 = URL.createObjectURL(blob1);
                link1.setAttribute('href', url1);
                link1.setAttribute('download', "tokens.xlsx");
                link1.style.visibility = 'hidden';
                document.body.appendChild(link1);
                link1.click();
                document.body.removeChild(link1);
            }
        }
    }
}

export async function exportXLSX(tokens, langs) {

    if (tokens.length > 0) {
        var xlsx3 = convertToXLSXDevDoneAndTranslator(tokens, langs);

        const workbook = new ExcelJs.Workbook();
        const worksheet = workbook.addWorksheet("Translations");

        worksheet.addRows(xlsx3);

        for (var col = 0; col < (xlsx3[0].length - 5); col++) {
            var tokenCol = worksheet.getColumn(col + 4);
            tokenCol.protection = { locked: false };
        }

        if (worksheet.getCell('C1').value !== 'en') {
            tokenCol = worksheet.getColumn(3);
            tokenCol.protection = { locked: false };
        }

        var headerRow = worksheet.getRow(1);
        headerRow.protection = { locked: true }

        await worksheet.protect('#editToken#', { formatCells: true, formatColumns: true, formatRows: true });

        headerRow.getCell(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '207345' }
        };

        headerRow.getCell(2).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '207345' }
        };

        headerRow.getCell(headerRow.cellCount).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '207345' }
        };

        headerRow.getCell(headerRow.cellCount - 1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '207345' }
        };

        const buffer = await workbook.xlsx.writeBuffer();


        /*var worksheet = XLSX.utils.aoa_to_sheet(xlsx3);
        worksheet['!protect'] = false;
        var new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
        /* generate array buffer */
        //var wbout = XLSX.write(new_workbook, {type:"array", bookType:'xlsx', password:'123' });


        /* create data URL */
        var blob1 = new Blob([buffer], { type: 'application/octet-stream' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob1, "tokens.xlsx");
        }
        else {
            var link1 = document.createElement('a');
            if (link1.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url1 = URL.createObjectURL(blob1);
                link1.setAttribute('href', url1);
                link1.setAttribute('download', "tokens.xlsx");
                link1.style.visibility = 'hidden';
                document.body.appendChild(link1);
                link1.click();
                document.body.removeChild(link1);
            }
        }
    }
}


export async function exportXLSXZip(tokens, translations, versions, langs, production) {
    var zip = new JSZip();
    for (var i = 0; i < translations.length; i++) {
        var xlsx3 = convertToXLSX(tokens[i], translations[i], langs);

        const workbook = new ExcelJs.Workbook();
        const worksheet = workbook.addWorksheet("Translations");

        worksheet.addRows(xlsx3);

        for (var col = 0; col < (xlsx3[0].length - 5); col++) {
            var tokenCol = worksheet.getColumn(col + 4);
            tokenCol.protection = { locked: false };
        }
        if (worksheet.getCell('C1').value !== 'en') {
            tokenCol = worksheet.getColumn(3);
            tokenCol.protection = { locked: false };
        }

        var headerRow = worksheet.getRow(1);
        headerRow.protection = { locked: true }

        await worksheet.protect('#editToken#', { formatCells: true, formatColumns: true, formatRows: true });

        headerRow.getCell(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '207345' }
        };

        headerRow.getCell(2).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '207345' }
        };

        headerRow.getCell(headerRow.actualCellCount).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '207345' }
        };

        headerRow.getCell(headerRow.actualCellCount - 1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '207345' }
        };

        const buffer = await workbook.xlsx.writeBuffer();

        zip.file(getAppById(translations[i][0]) + '_v' + versions[i][0] + '.xlsx', buffer, { binary: true });

    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
        // see FileSaver.js
        FileSaver.saveAs(content, "xlsx.zip");
        if (production) {
            saveBackup(content, 'xlsx');
        }
        return;
    });
}

export function exportImportedTokensXLSXFile(tokens) {
    var worksheet = XLSX.utils.json_to_sheet(tokens);
    var new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");

    /* generate array buffer */
    var wbout = XLSX.write(new_workbook, { type: "array", bookType: 'xlsx' });
    /* create data URL */
    var blob1 = new Blob([wbout], { type: 'application/octet-stream' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob1, "missingTokens.xlsx");
    }
    else {
        var link1 = document.createElement('a');
        if (link1.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url1 = URL.createObjectURL(blob1);
            link1.setAttribute('href', url1);
            link1.setAttribute('download', "missingTokens.xlsx");
            link1.style.visibility = 'hidden';
            document.body.appendChild(link1);
            link1.click();
            document.body.removeChild(link1);
        }
    }
}


export async function exportXLSXFile(tokens, translations, version, langs, production) {

    var xlsx3 = convertToXLSX(tokens, translations, langs);

    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet("Translations");

    worksheet.addRows(xlsx3);

    for (var col = 0; col < (xlsx3[0].length - 5); col++) {
        var tokenCol = worksheet.getColumn(col + 4);
        tokenCol.protection = { locked: false };
    }

    if (worksheet.getCell('C1').value !== 'en') {
        tokenCol = worksheet.getColumn(3);
        tokenCol.protection = { locked: false };
    }

    var headerRow = worksheet.getRow(1);
    headerRow.protection = { locked: true }

    await worksheet.protect('#editToken#', { formatCells: true, formatColumns: true, formatRows: true });

    headerRow.getCell(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '207345' }
    };

    headerRow.getCell(2).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '207345' }
    };

    headerRow.getCell(headerRow.actualCellCount).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '207345' }
    };

    headerRow.getCell(headerRow.actualCellCount - 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '207345' }
    };

    const buffer = await workbook.xlsx.writeBuffer();

    /* create data URL */
    var blob1 = new Blob([buffer], { type: 'application/octet-stream' });
    if (navigator.msSaveBlob) { // IE 10+
        if (version.length > 0)
            navigator.msSaveBlob(blob1, getAppById(tokens[0].app_id) + '_v' + version[0] + '.xlsx');

        else
            navigator.msSaveBlob(blob1, "tokens.xlsx");
    }
    else {
        var link1 = document.createElement('a');
        if (link1.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url1 = URL.createObjectURL(blob1);
            link1.setAttribute('href', url1);
            if (version.length > 0)
                link1.setAttribute('download', getAppById(tokens[0].app_id) + '_v' + version[0] + '.xlsx');
            else
                link1.setAttribute('download', "tokens.xlsx");
            link1.style.visibility = 'hidden';
            document.body.appendChild(link1);
            link1.click();
            document.body.removeChild(link1);
        }
    }
    if (production) {
        saveBackup(blob1, 'xlsx', getAppById(tokens[0].app_id) + '_v' + version[0]);
    }
    return;
}

function convertToXLSXDevDoneAndTranslator(array, langs) {
    var token_id;
    var firstLineTest = new Array(langs.length + 4);
    firstLineTest[0] = "Token";
    firstLineTest[1] = "Default Text";
    firstLineTest[firstLineTest.length - 1] = "App";
    firstLineTest[firstLineTest.length - 2] = "Description";
    for (var x = 0; x < langs.length; x++) {
        firstLineTest[x + 2] = langs[x];
    }
    var data = [];
    data.push(firstLineTest);
    var arrayLine;

    for (var i = 0; i < array.length; i++) {
        if (token_id !== array[i].token_id) {
            if (token_id !== undefined)
                data.push(arrayLine);

            arrayLine = new Array(langs.length + 4);
            arrayLine[0] = array[i].token;
            arrayLine[1] = array[i].default_text;
            arrayLine[arrayLine.length - 2] = array[i].description;
            arrayLine[arrayLine.length - 1] = getAppLabelById(array[i].app_id);

            arrayLine[getLangIdInArray(langs, array[i].lang) + 2] = array[i].translation;

        }
        else {
            arrayLine[getLangIdInArray(langs, array[i].lang) + 2] = array[i].translation;
            if (!arrayLine[arrayLine.length - 1].includes(getAppLabelById(array[i].app_id)))
                arrayLine[arrayLine.length - 1] += ", " + getAppLabelById(array[i].app_id);
        }
        token_id = array[i].token_id;
    }
    data.push(arrayLine);
    return data;
}

function convertToXLSXEvolis(tokens, translations, langs) {
    var arrayTokens = typeof tokens != 'object' ? JSON.parse(tokens) : tokens;
    var arrayTranslations = typeof translations != 'object' ? JSON.parse(translations) : translations;
    var firstLineTest = new Array(langs.length + 4);
    firstLineTest[0] = "Token";
    firstLineTest[1] = "Default Text";
    firstLineTest[firstLineTest.length - 1] = "App";
    firstLineTest[firstLineTest.length - 2] = "Description";
    for (var x = 0; x < langs.length; x++) {
        firstLineTest[x + 2] = langs[x];
    }
    var data = [];
    data.push(firstLineTest);
    var arrayLine = [];
    var hasToken = false;

    for (var t = 0; t < arrayTokens.length; t++) {
        for (var d = 0; d < data.length; d++) {
            if (data[d][0] === arrayTokens[t].token) {
                hasToken = true;
                if (!data[d][arrayLine.length - 1].includes(getAppLabelById(arrayTokens[t].app_id)))
                    data[d][arrayLine.length - 1] += ", " + getAppLabelById(arrayTokens[t].app_id);
                break;
            }
        }
        if (!hasToken) {
            arrayLine = new Array(langs.length + 4);
            arrayLine[0] = arrayTokens[t].token;
            arrayLine[1] = arrayTokens[t].default_text;
            arrayLine[arrayLine.length - 2] = arrayTokens[t].description;
            arrayLine[arrayLine.length - 1] = getAppLabelById(arrayTokens[t].app_id);

            data.push(arrayLine);
        }

        hasToken = false;

    }

    for (var l = 0; l < arrayTranslations.length; l++) {
        if (arrayTranslations[l].length > 0) {
            var langId = getLangIdInArray(langs, arrayTranslations[l][0].lang);
            for (t = 0; t < arrayTranslations[l].length; t++) {
                for (d = 0; d < data.length; d++) {
                    if (data[d][0] === arrayTranslations[l][t].token) {
                        if (data[d][langId + 2] === undefined)
                            data[d][langId + 2] = arrayTranslations[l][t].translation
                        break;
                    }
                }
            }
        }
    }
    return data;
}

function convertToXLSX(tokens, translations, langs) {
    var arrayTokens = typeof tokens != 'object' ? JSON.parse(tokens) : tokens;
    var arrayTranslations = typeof translations != 'object' ? JSON.parse(translations) : translations;
    var firstLineTest = new Array(langs.length + 4);
    firstLineTest[0] = "Token";
    firstLineTest[1] = "Default Text";
    firstLineTest[firstLineTest.length - 1] = "App";
    firstLineTest[firstLineTest.length - 2] = "Description";
    for (var x = 0; x < langs.length; x++) {
        firstLineTest[x + 2] = langs[x];
    }
    var data = [];
    data.push(firstLineTest);
    var arrayLine = [];
    var token_id;

    for (var t = 0; t < arrayTokens.length; t++) {
        if (token_id !== arrayTokens[t].token_id) {
            if (token_id !== undefined)
                data.push(arrayLine);

            arrayLine = new Array(langs.length + 4);
            arrayLine[0] = arrayTokens[t].token;
            arrayLine[1] = arrayTokens[t].default_text;
            arrayLine[arrayLine.length - 2] = arrayTokens[t].description;
            arrayLine[arrayLine.length - 1] = getAppLabelById(arrayTokens[t].app_id);

        }
        else {
            if (!arrayLine[arrayLine.length - 1].includes(getAppLabelById(arrayTokens[t].app_id)))
                arrayLine[arrayLine.length - 1] += ", " + getAppLabelById(arrayTokens[t].app_id);
        }
        token_id = arrayTokens[t].token_id;
    }

    for (var l = 1; l < arrayTranslations.length; l++) {
        if (arrayTranslations[l].length > 0) {
            var langId = getLangIdInArray(langs, arrayTranslations[l][0].lang);
            for (t = 0; t < arrayTranslations[l].length; t++) {
                for (var d = 0; d < data.length; d++) {
                    if (data[d][0] === arrayTranslations[l][t].token) {
                        if (data[d][langId + 2] === undefined)
                            data[d][langId + 2] = arrayTranslations[l][t].translation
                        break;
                    }
                }
            }
        }
    }
    return data;
}

// ----------------------------------------------------------------------------------------------------------------------------------------

export function exportMultipleJSONZip(tokens, versions, production) {
    var zip = new JSZip();
    for (var i = 0; i < tokens.length; i++) {
        var folder = zip.folder(getAppById(tokens[i][0]) + '_v' + versions[i][0]);
        for (var l = 1; l < tokens[i].length; l++) {
            if (tokens[i][l].length > 0)
                if (tokens[i][l][0].app_id === 2)
                    folder.file('cardPresso_' + tokens[i][l][0].lang + '.json', JSON.stringify(tokens[i][l]))
                else
                    folder.file(tokens[i][l][0].lang + '.json', JSON.stringify(tokens[i][l]))
        }
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
        // see FileSaver.js
        FileSaver.saveAs(content, "json.zip");
        if (production) {
            saveBackup(content, 'json');
        }
    });
}

export function exportJSONZip(tokens, version, production) {
    var zip = new JSZip();
    var folder;

    if (version.length === 0) {
        folder = zip.folder('tokens');
    } else {
        folder = zip.folder(getAppById(tokens[0]) + '_v' + version[0]);
    }

    for (var i = 0; i < tokens.length; i++) {
        if (tokens[i].length > 0)
            if (tokens[i][0].app_id === 2) {
                folder.file('cardPresso_' + tokens[i][0].lang + '.json', JSON.stringify(tokens[i]))
            } else {
                folder.file(tokens[i][0].lang + '.json', JSON.stringify(tokens[i]))
            }
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
        // see FileSaver.js
        FileSaver.saveAs(content, "json.zip");
        if (production) {
            saveBackup(content, 'json', getAppById(tokens[0]) + '_v' + version[0]);
        }
    });
}

// ----------------------------------------------------------------------------------------------------------------------------------------

export function exportMultipleTRXZip(tokens, versions, production) {
    var zip = new JSZip();
    for (var i = 0; i < tokens.length; i++) {
        var folder = zip.folder(getAppById(tokens[i][0]) + '_v' + versions[i][0]);
        for (var l = 1; l < tokens[i].length; l++) {
            if (tokens[i][l].length > 0)
                if (tokens[i][l][0].app_id === 2)
                    folder.file('cardPresso_' + tokens[i][l][0].lang + '.trx', exportTRXFile(tokens[i][l]))
                else
                    folder.file(tokens[i][l][0].lang + '.trx', exportTRXFile(tokens[i][l]))
        }

    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
        // see FileSaver.js
        FileSaver.saveAs(content, "trx.zip");
        if (production)
            saveBackup(content, 'trx');
    });
}


export function exportTRXZip(tokens, version, production) {
    var zip = new JSZip();
    var folder;
    if (version.length === 0)
        folder = zip.folder('tokens');
    else
        folder = zip.folder(getAppById(tokens[0]) + '_v' + version[0]);
    for (var i = 0; i < tokens.length; i++) {
        if (tokens[i].length > 0)
            if (tokens[i][0].app_id === 2)
                folder.file('cardPresso_' + tokens[i][0].lang + '.trx', exportTRXFile(tokens[i]))
            else
                folder.file(tokens[i][0].lang + '.trx', exportTRXFile(tokens[i]))
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
        // see FileSaver.js
        FileSaver.saveAs(content, "trx.zip");
        if (production)
            saveBackup(content, 'trx', getAppById(tokens[0]) + '_v' + version[0]);
    });
}

function exportTRXFile(items) {
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    if (jsonObject !== undefined) {
        var trx = hex2a(convertToTRX(jsonObject));
        var trx1 = new Uint8Array(trx);

        return trx1;
    }
}

function convertToTRX(object) {
    var str = '';
    if (object.length !== 2) {
        var array = typeof object != 'object' ? JSON.parse(object) : object;
        for (var x = 0; x < newLangs.length; x++) {
            if (newLangs[x].lang === array[0].lang) {
                var first = toHex(newLangs[x].description);
            }
        }
        var firstSize = ('000' + (first.length / 2).toString(16)).slice(-4);
        var second = toHex(array[0].lang + '*');
        var secondSize = ('000' + (second.length / 2).toString(16)).slice(-4);

        str = '0000' + firstSize + first + '0000' + secondSize + second;
        var allSize = ('000' + (str.length / 2).toString(16)).slice(-4);
        str = 'f00000' + allSize + '0000' + firstSize + first + '0000' + secondSize + second;
        for (var i = 0; i < array.length; i++) {
            var line = '';
            var arrayLine = ['f10000', '', '0000', '', '', '0000', '', ''];

            arrayLine[4] = toHex(array[i].token);
            arrayLine[3] = ('000' + (arrayLine[4].length / 2).toString(16)).slice(-4);

            arrayLine[7] = toHex(array[i].translation);
            arrayLine[6] = ('000' + (arrayLine[7].length / 2).toString(16)).slice(-4);

            var len = 0;
            for (var y = 0; y < arrayLine.length; y++) {
                if (y !== 0 && y !== 1)
                    len += arrayLine[y].length;
            }
            arrayLine[1] = ('000' + (len / 2).toString(16)).slice(-4);

            for (var l = 0; l < arrayLine.length; l++) {
                if (line !== '') line += ''
                line += arrayLine[l];
            }
            str += line;
        }
    }
    return str;
}

function hex2a(hex) {
    var byteArray = [];
    for (var i = 0; (i < hex.length); i += 2)
        byteArray.push(parseInt(hex.substr(i, 2), 16));
    return byteArray;
}

function toHex(str) {
    var hex;

    var result = '';
    for (var i = 0; i < str.length; i++) {
        hex = str.charCodeAt(i).toString(16);
        result += ('000' + hex).slice(-4);
    }

    return result
}

export function saveToLocalStorage(newState) {
    try {
        const serializedState = JSON.stringify({
            authToken: newState.authToken,
            userEmail: newState.userEmail,
            userExport: newState.userExport,
            userType: newState.userType,
            needPassword: newState.needPassword,
            tabIndex: newState.tabIndex,
            tabIndexName: newState.tabIndexName,
        });
        localStorage.setItem('state', serializedState);
    }
    catch (e) {
        console.log(e);
    }
}

export function clearLocalStorage() {
    try {
        localStorage.removeItem('state');
    }
    catch (e) {
        console.log(e);
    }
}

export function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState);
    }
    catch (e) {
        console.log(e);
        return undefined;
    }
}