import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';


function TablePaginationActions(props) { 

  const { classes } = props;

  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => { 
    onChangePage(event, 0);
 }

  const handleBackButtonClick = event => { 
    onChangePage(event, page - 1);
 }

  const handleNextButtonClick = event => { 
    onChangePage(event, page + 1);
 }

  const handleLastPageButtonClick = event => { 
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
 }

  return (
    <div className={ classes.pagination }>
      <IconButton onClick={ handleFirstPageButtonClick } disabled={ page === 0 } aria-label='First Page'>
        { theme.direction === 'rtl' ? <LastPage /> : <FirstPage /> }
      </IconButton>
      <IconButton onClick={ handleBackButtonClick } disabled={ page === 0 } aria-label='Previous Page'>
        { theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft /> }
      </IconButton>
      <IconButton onClick={ handleNextButtonClick } disabled={ page >= Math.ceil(count / rowsPerPage) - 1 } aria-label='Next Page'>
        { theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight /> }
      </IconButton>
      <IconButton onClick={ handleLastPageButtonClick } disabled={ page >= Math.ceil(count / rowsPerPage) - 1 } aria-label='Last Page'>
        { theme.direction === 'rtl' ? <FirstPage /> : <LastPage /> }
      </IconButton>
    </div>
  );
 }

TablePaginationActions.propTypes = { 
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
 };

 export default connect( null, { } )( withStyles( gStyles )( TablePaginationActions ) );