import { store } from '../index';
import { setDetailOp, setTranslatorList, refreshTranslatorTable, setTranslatorSelected } from '../utils/Reducer';
import { gError, webAPIUser, webAPIPass } from '../utils/BaseRest';
import { newLangs } from '../utils/Utils';


//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getTranslators
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getTranslators( from, limit ) {

  const onError = error => {
    gError( 'getTranslators', error ); 
    store.dispatch(  setTranslatorList( { count:0, items:[] } ) );
  }

  store.dispatch( setTranslatorList( undefined ) );
  var authToken = store.getState().authToken;
  var search = store.getState().translatorSearch;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/translators?from=' + from + '&limit=' + limit + ( search === '' ? '' : ( '&search=' + search ) ) + '&authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        store.dispatch( setTranslatorList( json ) );
      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// getTranslator
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function getTranslator() {

  const onError = error => {
    gError( 'getTranslator', error ); 
    store.dispatch( setDetailOp( undefined ) );
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/translators/' + store.getState().detailValue + '?authToken=' + authToken, {
    method: 'GET',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json'
    }
  } )
  .then( response => {
    if ( response.ok ) {
      response.json().then( json => {
        var langs = newLangs.map( item => {
        var checked = false;
        for( var i=0 ; i < json.langs.length ; i++ ) {
          if( json.langs[i].lang === item.lang ) {
            checked = true;
            break;
          }
        }
        return { lang: item.lang, checked: checked };
      });
      var translator = json;
      if(translator.export === 1)
        translator.export = false;
      else
        translator.export = true;
      translator.langs = langs;
      store.dispatch( setTranslatorSelected( translator ) );      } )
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// createTranslator
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function createTranslator( translator ) {

  const onError = error => {
    gError( 'createTranslator', error ); 
    store.dispatch( setDetailOp( undefined ) );
  }

  translator.langs = translator.langs.filter( lang => lang.checked );
  translator.langs = translator.langs.map( lang => lang.lang );
  translator.export = translator.export ? 0 : 1;

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/translators/create?authToken=' + authToken, {
    method: 'POST',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( translator )
  } )
  .then( response => {
    if ( response.ok ) {
        store.dispatch( refreshTranslatorTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}




//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setTranslator
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setTranslator( translator ) {

  const onError = error => {
    gError( 'setTranslator', error ); 
  }

  translator.langs = translator.langs.filter( lang => lang.checked );
  translator.langs = translator.langs.map( lang => lang.lang );
  translator.export = translator.export ? 0 : 1;

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/translators/' + store.getState().detailValue + '?authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    },
    body : JSON.stringify( translator )
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshTranslatorTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// setTranslatorState
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function setTranslatorState( email, state ) {

  const onError = error => {
    gError( 'setTranslatorState', error );
    store.dispatch( setDetailOp( undefined ) ); 
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/translators/' + email + '/state?state=' + state + '&authToken=' + authToken, {
    method: 'PUT',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
    }
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshTranslatorTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}



//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
// deleteTranslator
//------------------------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------------------------------------------
export function deleteTranslator( translator ) {

  const onError = error => {
    gError( 'deleteTranslator', error ); 
  }

  var authToken = store.getState().authToken;
  fetch( process.env.REACT_APP_WEB_API_URL + '/v1/translators/' + translator.email + '?authToken=' + authToken, {
    method: 'DELETE',
    headers: {
      'Authorization' : 'Basic ' + btoa(webAPIUser + ":" + webAPIPass),
      'Accept' : 'application/json',
    },
  } )
  .then( response => {
    if ( response.ok ) {
      store.dispatch( refreshTranslatorTable( ) );
      return;
    }
    response.json().then( json => onError( json.message ) );
  } )
  .catch( error => onError( error.message ) );
}