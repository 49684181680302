import { saveToLocalStorage, clearLocalStorage } from './Utils';

export const initialState = {
    authToken: undefined,
    userEmail: '',
    userExport: false,
    needPassword: false,
    changePass: false,
    tabIndex: 0,
    tabIndexName: undefined,
    userType: 0,

    errorMessageList: [],

    tokenTableForceRefresh: false,
    tokenTranslationTableForceRefresh: false,
    tokenDetailForceRefresh: false,
    tokenPage: 0,
    tokenRowsPerPage: 20,
    tokenSearch: '',
    tokenAppFilter: 0,
    tokenStateFilter: 0,
    tokenDevFilter: 0,

    tokenList: undefined,
    tokenSelected: undefined,
    tokenTranslationList: undefined,
    tokenApps: undefined,

    developersList: undefined,

    translatorTableForceRefresh: false,
    translatorPage: 0,
    translatorRowsPerPage: 20,
    translatorSearch: '',
    translatorList: undefined,
    translatorSelected: undefined,

    transUpdated: false,

    exporting: false,
    importSuccess: false,
    importSuccessInfo: [],
    exportMissing: false,
    exportEmptyTokens: false,

    exportList: undefined,

    detailOpen: undefined,
    detailEdit: false,
    detailValue: undefined,
};

export const setLoginInfo = value => ( { type: 'SET_LOGIN_INFO', value } );
export const setTabIndex = value => ( { type: 'SET_TAB_INDEX', value } );
export const passChanged = value => ( { type: 'PASSWORD_CHANGED', value } );
export const setChangePass = value => ( { type: 'SET_CHANGE_PASS', value } );
export const logout = value => ( { type: 'LOGOUT', value } );
export const setAllowUpgrades = value => ( { type: 'SET_ALLOW_UPGRADES', value } );

export const addErrorMessage = value => ( { type: 'ADD_ERROR_MESSAGE', value } );
export const clearErrorMessageList = value => ( { type: 'CLEAR_ERROR_MESSAGE_LIST', value } );

export const setTokenPage = value => ( { type: 'SET_TOKEN_PAGE', value } );
export const setTokenRowsPerPage = value => ( { type: 'SET_TOKEN_ROWS_PER_PAGE', value } );
export const setTokenSearch = value => ( { type: 'SET_TOKEN_SEARCH', value } );
export const setTokenAppFilter = value => ( { type: 'SET_TOKEN_APP_FILTER', value } );
export const setTokenStateFilter = value => ( { type: 'SET_TOKEN_STATE_FILTER', value } );
export const setTokenDevFilter = value => ( { type: 'SET_TOKEN_DEV_FILTER', value } );

export const setTokenList = value => ( { type: 'SET_TOKEN_LIST', value } );
export const setTranslatorLangsList = value => ( { type: 'SET_TRANSLATOR_LANGS_LIST', value } );
export const setTokenSelected = value => ( { type: 'SET_TOKEN_SELECTED', value } );
export const setTokenTranslationList = value => ( { type: 'SET_TOKEN_TRANSLATION_LIST', value } );
export const setTokenApps = value => ( { type: 'SET_TOKEN_APPS', value } );

export const setDevelopersList = value => ( { type: 'SET_DEVELOPERS_LIST', value } );

export const refreshTokenTable = value => ( { type: 'REFRESH_TOKEN_TABLE', value } );
export const refreshTokenDetail = value => ( { type: 'REFRESH_TOKEN_DETAIL_TABLE', value } );
export const refreshTokenTranslationTable = value => ( { type: 'REFRESH_TOKEN_TRANSLATION_TABLE', value } );



export const setTranslatorPage = value => ( { type: 'SET_TRANSLATOR_PAGE', value } );
export const setTranslatorRowsPerPage = value => ( { type: 'SET_TRANSLATOR_ROWS_PER_PAGE', value } );
export const setTranslatorSearch = value => ( { type: 'SET_TRANSLATOR_SEARCH', value } );
export const setTranslatorList = value => ( { type: 'SET_TRANSLATOR_LIST', value } );
export const setTranslatorSelected = value => ( { type: 'SET_TRANSLATOR_SELECTED', value } );

export const refreshTranslatorTable = value => ( { type: 'REFRESH_TRANSLATOR_TABLE', value } );

export const setExportingTokens = value => ( { type: 'EXPORTING_TOKENS', value } );
export const setImportSuccessTokens = value => ( { type: 'IMPORT_SUCCESS_TOKENS', value } );
export const setImportSuccessTokensInfo = value => ( { type: 'IMPORT_SUCCESS_TOKENS_INFO', value } );
export const setExportMissingTokens = value => ( { type: 'EXPORT_MISSING_TOKENS', value } );
export const setExportEmptyTokens = value => ( { type: 'EXPORTING_EMPTY_TOKENS', value } );


export const setTransUpdated = value => ( { type: 'SET_TRANSLATED_UPDATED', value } );

export const setExportList = value => ( { type: 'SET_EXPORT_LIST', value } );


export const setDetailOp = value => ( { type: 'SET_DETAIL_OPEN', value } );

export default function rootReducer( state = initialState, action ) {
  var newState;

  switch ( action.type ) {

    case 'SET_LOGIN_INFO':
      newState = { ...state,
        authToken: action.value.token,
        userEmail: action.value.email,
        userExport: action.value.export === 0 ? true : false,
        userType: action.value.userType,
        needPassword: action.value.needPassword,
        tabIndex: 0,
        tabIndexName: 'token' };
      saveToLocalStorage( newState );
      return newState;

    case 'SET_TAB_INDEX':
      const tabs = [ 'token', 'translator' ];
      newState = { ...state,
        tabIndex: action.value,
        tabIndexName: tabs[ action.value ] };
      saveToLocalStorage( newState );
      return newState;      

    case 'PASSWORD_CHANGED':
    newState = { ...state, needPassword: false, changePass: false };
    saveToLocalStorage( newState );
    return newState;      

    case 'SET_CHANGE_PASS':
      return { ...state, changePass: action.value };
    case 'LOGOUT':
      clearLocalStorage();
      return initialState;


    case 'ADD_ERROR_MESSAGE':
      if ( state.errorMessageList.find( item => { return item === action.value; } ) === undefined )
        return { ...state, errorMessageList: [ ...state.errorMessageList, action.value ] };
      return state;
    case 'CLEAR_ERROR_MESSAGE_LIST':
      return { ...state, errorMessageList: [] };
     
    case 'SET_TOKEN_PAGE':
      return { ...state, tokenPage: action.value };
    case 'SET_TOKEN_ROWS_PER_PAGE':
      //Each value of rowsPerPage must be 2n(base), examples: 10, 20, 40; 4, 8, 16; 5, 10, 20, 40, 80
      return { ...state, tokenRowsPerPage: action.value, tokenPage: Math.trunc( state.tokenPage * ( state.tokenRowsPerPage / action.value ) ) };
    case 'SET_TOKEN_SEARCH':
      return { ...state, tokenSearch: action.value, tokenPage: 0 };

    case 'SET_TOKEN_APP_FILTER':
      return { ...state, tokenAppFilter: action.value };
    case 'SET_TOKEN_STATE_FILTER':
      return { ...state, tokenStateFilter: action.value };
    case 'SET_TOKEN_DEV_FILTER':
      return { ...state, tokenDevFilter: action.value };

    case 'SET_TOKEN_LIST':
      return { ...state, tokenList: action.value };
    case 'SET_TOKEN_SELECTED':
      return { ...state, tokenSelected: action.value };
    case 'SET_TOKEN_TRANSLATION_LIST':
      return { ...state, tokenTranslationList: action.value };
    case 'SET_TOKEN_APPS':
      return { ...state, tokenApps: action.value };

    case 'SET_DEVELOPERS_LIST':
      return { ...state, developersList: action.value };

    case 'REFRESH_TOKEN_TABLE':
      return { ...state, tokenTableForceRefresh:!state.tokenTableForceRefresh, tokenPage: 0 };
    case 'REFRESH_TOKEN_DETAIL_TABLE':
      return { ...state, tokenDetailForceRefresh:!state.tokenDetailForceRefresh };
    case 'REFRESH_TOKEN_TRANSLATION_TABLE':
      return { ...state, tokenTranslationsTableForceRefresh:!state.tokenTranslationsTableForceRefresh, tokenPage: 0 };


  
    case 'SET_TRANSLATOR_PAGE':
      return { ...state, translatorPage: action.value };
    case 'SET_TRANSLATOR_ROWS_PER_PAGE':
      //Each value of rowsPerPage must be 2n(base), examples: 10, 20, 40; 4, 8, 16; 5, 10, 20, 40, 80
      return { ...state, translatorRowsPerPage: action.value, translatorPage: Math.trunc( state.translatorPage * ( state.translatorRowsPerPage / action.value ) ) };
    case 'SET_TRANSLATOR_SEARCH':
      return { ...state, translatorSearch: action.value, translatorPage: 0 };
    case 'SET_TRANSLATOR_LIST':
      return { ...state, translatorList: action.value };
    case 'SET_TRANSLATOR_SELECTED':
      return { ...state, translatorSelected: action.value };


    case 'REFRESH_TRANSLATOR_TABLE':
      return { ...state, translatorTableForceRefresh:!state.translatorTableForceRefresh, translatorPage: 0 };

    
   case 'EXPORTING_TOKENS':
    return { ...state, exporting: action.value};

  case 'IMPORT_SUCCESS_TOKENS':
    return { ...state, importSuccess: action.value};

  case 'IMPORT_SUCCESS_TOKENS_INFO':
    return { ...state, importSuccessInfo: action.value};

  case 'EXPORT_MISSING_TOKENS':
    return { ...state, exportMissing: action.value};
   
  case 'EXPORTING_EMPTY_TOKENS':
    return { ...state, exportEmptyTokens: action.value};

   
    case 'SET_TRANSLATED_UPDATED':
      return { ...state, transUpdated: action.value};

    case 'SET_EXPORT_LIST':
      return { ...state, exportList: action.value };

    case 'SET_DETAIL_OPEN':
      if ( action.value === undefined )
        return { ...state, detailOpen: undefined, detailEdit: false, detailValue: undefined, tokenSelected: undefined, translatorSelected: undefined, };
      return { ...state, detailOpen: action.value.type, detailEdit: action.value.edit, detailValue: action.value.value };

    default:
      return state;
  }
}
