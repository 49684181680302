import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme ({
    palette: {
        primary: {
            main: '#25282F',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#5BC592',
            contrastText: '#FFFFFF',

        },
        background: {
            paper: '#F7F7F7',
        }
    },
} );

export const gStyles = theme => ( {
    app: {
        padding: theme.spacing(0),
    },
    appBar: {
        backgroundColor: theme.palette.primary.main,
    },
    appUserBox: {
        display: 'flex',
        flexFlow: 'column',
    },
    appUserItem: {
        margin: 'auto',
    },
    topBanner: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    logoImg: {
        width: '255px',
    },

    menuToolbar: {
        minHeight: 0
    },

    containerDashboard: {
        padding: theme.spacing(0, 2, 2, 2)        
    },
    
    title: {
        marginTop: '12px',
        color: '#5BC592',
    },

    workarea: {
        margin: theme.spacing(0), 
        padding: theme.spacing(2),
        textAlign: 'left',
        position:'relative', 
        minHeight: theme.spacing(22),      
    },
    stickyToolbar:{
        opacity: '1',
        backgroundColor: '#ECF0F1',
        position: 'fixed',
        top: '48px',
        right: 0,
        left:0,
        zIndex: 9,
        paddingLeft: '30px',
        paddingRight: '30px'
    },

    tableArea: {
        marginTop: theme.spacing(1),
        minHeight: theme.spacing(25),
    },

    translatorLanguagesTitle: {
        fontWeight: 'bold',
        color:'#25282F',
        paddingTop: '15px',
        paddingBottom: '15px'
    },
    translatorLanguageLabel: {
        cursor: 'pointer'        
    },

    tableHeaderCell: {
        fontSize:15,
        fontWeight: 'bold',
        color:'#25282F',
        position: 'relative',
        cursor: 'pointer'
    },
    tableHeaderCellOrderIcon: {
        position: 'absolute',
        right: 0
    },

    tableCellNoBorder: {
        border:'0px',
    },

    tableButton: {
        padding: '0px',
    },

    flexHeaderDetail: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    flexHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    flexExpand: {
        flex: 1,
    },
    filtersHolder:{
        display: 'flex'
    },
    BtnsHolder:{
        display: 'flex'
    },
    
    flexButton: {
        // alignSelf: 'flex-end',  
        height: '39px',
        margin: '12px 8px',
    }, 
    tokenDetailBtn:{
        height: 30,
        alignSelf: 'center',
        marginLeft: '20px'

    },

    searchPaper: {
        height: '39px',
        marginTop: '12px',
        padding: '0px 4px',
        display: 'flex',
        width: theme.spacing(30),
        backgroundColor: '#FFFFFF',
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },

    link: {
        cursor:'pointer',
        textDecoration: 'underline',
    },
    spinner: {
        position: 'absolute',
        top:'40%',
        left:'50%',
    },

    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    formSubmit: {
        margin: theme.spacing(3, 0, 2),
    },

    dividerSpacer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    signInArea: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    signInIcon: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },

    pagination: { 
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),
    },

    topButton: {
        display: 'flex',
        justifyContent: 'center'
    },

    lineButtonBox: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(1),
    },
    lineButton: {
        margin: '8px',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 130,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    errorsHandler: {
        color: 'red'
    },
    errorsHandlerP: {
        lineHeight: 0
    },
    fileInfoP: {
        lineHeight: '22px'
    },
    previewImage:{
        maxWidth: '100%',
        width: '100%',
        height: 'auto'
    }



});

export const fieldLimits = {
    signIn: {
        user : {
            size: 48,
        },
        password: {
            size:32,
        },
    },
    translator: {
        email: {
            size:128,
        },
        password: {
            size:128,
        },
    },
}