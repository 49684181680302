import React from 'react';
import ReactDOM  from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer, { initialState } from './utils/Reducer';
import { loadFromLocalStorage } from './utils/Utils';

import { ThemeProvider } from '@material-ui/styles';
import theme from './utils/Styles';

import App from './App';

const persistedState = loadFromLocalStorage();
export const store = createStore(rootReducer, { ...initialState, ...persistedState } );

ReactDOM.render(
  <ThemeProvider theme={ theme }>
    <Provider store={ store }>
      <App />
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);
