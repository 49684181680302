import React, { useEffect, useRef }  from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { setDetailOp, setTranslatorSelected } from '../utils/Reducer';
import { getTranslator, createTranslator, setTranslator } from './TranslatorRest';
import { gStyles, fieldLimits } from '../utils/Styles';
import { checkUser, newLangs, getLangDescription } from '../utils/Utils';

import { withStyles } from '@material-ui/styles';
import { Typography, FormControlLabel } from '@material-ui/core';
import { CircularProgress, TextField, Button, Grid, Checkbox, Paper, IconButton, Switch } from '@material-ui/core';
import { Close } from '@material-ui/icons';

let strings=new LocalizedStrings({
	en:{
    labelEmail:'Email',
    labelPass:'Password',
    tableHeaderLangs: 'Languages',
    buttonConfirm:'Save',
    buttonCancel: 'Cancel',
    toolTipEdit: 'Edit translation',

    translationsTitle:'Translations',
    tableHeaderLang: 'Language',
    tableHeaderState: 'State',
    tableHeaderTranslation: 'Translation',
    titleTranslation: 'New Translation',
	}
});

function TranslatorDetail( props ) { 

  const { classes } = props;

  const { userType } = props;
  const { tokenDetailForceRefresh } = props;
  const { translatorSelected, setTranslatorSelected } = props;
  const { detailValue, setDetailOp } = props;

  const [ values, setValues ] = React.useState( {
    valid: false,
    email: '',
    password: '',
    langs: [],
    export: false,
  } );
  const [stickyToolbar, setStickyToolbar] = React.useState(false);
  const toolbalHolder = useRef(); 
  const [errors, setErrors] = React.useState([]);
  
  useEffect(
    ( ) => {
      if ( detailValue !== '' )
        getTranslator( );
      else
        setTranslatorSelected( {
          email: '',
          password: '',
          langs: newLangs.map( ( lang ) => { return { checked: false, lang: lang.lang} } ),
          export: false,
        } );

        const handleScroll = () => {
        if (window.pageYOffset >= 100){
          setStickyToolbar(true);
        }else{ 
          setStickyToolbar(false);
        }   
      } 
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    },
    [ detailValue, setTranslatorSelected, tokenDetailForceRefresh ]
  );

  if ( translatorSelected !== undefined && !values.valid ) {
    setValues( {
      valid: true,
      email: translatorSelected.email,
      password: translatorSelected.password,
      langs: translatorSelected.langs,
      export: translatorSelected.export,
    } );
  }

  const handleChange = fieldName => event => {
    setValues( { ...values, [fieldName]: event.target.value } );
  };

  const onClose = () => {
    setDetailOp( undefined );
  };
  
  function validateForm() {
    const errors = [];

    if (values.email === '') {
      errors.push("Email can't be empty");
    }
    if (values.password === '') {
      errors.push("Password can't be empty");
    } 

    var selectedLangs = 0;
    for(var x=0; x<values.langs.length; x++){
      if(values.langs[x].checked === true){
        selectedLangs++; 
      }
    }
   
    if (selectedLangs === 0) {
      errors.push("You must select at least one language");
    }    
  
    return errors;
  }

  const submitForm = (event) => {
      const errors = validateForm();
      if (errors.length > 0) {
        setErrors(errors); 
      }else{
        onConfirm( event );   
      } 
  }

  const onConfirm = ( event ) => {
    event.preventDefault();
    if (  detailValue === '' )
      createTranslator( values );
    else
      setTranslator( values );
      onClose();    
  };

  const handleExportCheckboxChange = export1 => {
    setValues( {...values, export: !values.export} );
  };

  const handleLangsChange = lang => {
    lang.checked = !lang.checked;
    setValues( {...values, langs: values.langs} );
  };

  return (
    <div className={ classes.containerDashboard } >
      <Paper className={ classes.workarea }>
      <div ref={toolbalHolder} className={ classes.flexHeaderDetail + ' ' + (stickyToolbar ? classes.stickyToolbar : '') }> 

          <FormControlLabel control={<Switch checked={values.export} onClick={ ( ) => handleExportCheckboxChange(values.export) } />} label="Enable export" />

          <Button variant="contained" color="secondary" className={ classes.flexButton } onClick={ ( event ) => submitForm( event ) }>
            { strings.buttonConfirm }
          </Button>

          <IconButton size='small' className={ classes.tokenDetailBtn } onClick={ ( ) => onClose() } >
            <Close/>
          </IconButton>

      </div>
      { translatorSelected !== undefined && 
        <div>
          <form className={ classes.form } autoComplete="off" onSubmit={ ( event ) => onConfirm( event ) }>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <div className={ classes.errorsHandler }>
                    {errors.map(error => ( 
                      <p className={ classes.errorsHandlerP } key={error}>Error: {error}</p>
                    ))}  
                  </div>
                </Grid> 
                <Grid item xs={12}> 
                  <TextField fullWidth variant='outlined' margin='dense' required id='email' disabled={ (detailValue !== '') }
                  label={ strings.labelEmail } value={ values.email } onChange={ handleChange('email') }
                  inputProps={ { maxLength: fieldLimits.translator.email.size } }/>
                  <TextField fullWidth variant='outlined' margin='dense' required id='password'
                  label={ strings.labelPass } value={ values.password } onChange={ handleChange('password') }
                  inputProps={ { maxLength: fieldLimits.translator.password.size } }/>
                </Grid>
                { checkUser(userType) !== 'TRANSLATOR' &&
                  <Grid container spacing={0}>
                    <Grid item xs={12}>  
                      <Typography className={ classes.translatorLanguagesTitle } variant="h6" component="h6">
                          { strings.tableHeaderLangs }  
                      </Typography>
                    </Grid>

                    { values.langs.map( (lang, index) => (
                      <Grid item xs={3}>
                        <Checkbox checked={ lang.checked } color='primary' onClick={ ( ) => handleLangsChange( lang ) } />                           
                        <Typography className={ classes.translatorLanguageLabel } variant="button" component="span" onClick={ ( ) => handleLangsChange( lang ) }>
                          { getLangDescription(lang.lang) }
                        </Typography>
                      </Grid>
                    ))}

                  </Grid>
                }                 
              </Grid>          
          </form>
        </div>
      }
      { translatorSelected === undefined &&
        <CircularProgress className={ classes.spinner }/>
      }
      </Paper>
    </div>
  );
 }

const mapStateToProps = state => { 
  return {
    userType: state.userType,
    tokenDetailForceRefresh: state.tokenDetailForceRefresh,
    translatorSelected: state.translatorSelected,
    detailValue: state.detailValue,
    detailEdit: state.detailEdit,
  }
};

export default connect( mapStateToProps, { setDetailOp, setTranslatorSelected } )( withStyles( gStyles )( TranslatorDetail ) );