import React, { useEffect } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { store } from '../index';
import { setTokenPage, setTokenRowsPerPage, setTokenSearch, setTokenAppFilter, setTokenStateFilter, setTokenDevFilter, setDetailOp, setExportingTokens, setImportSuccessTokens, setExportMissingTokens, setExportEmptyTokens, refreshTokenTable } from '../utils/Reducer';
import { getTokens, deleteToken, exportXLSXTokens, setTokenState, exportTRXTokens, undoFromVerified, setTokenTranslator, exportDevDone, getDevelopers, importXLSXTokens, exportEvolisXLSXTokens, exportTranslatorXLSXTokens, exportXLSXTokensProduction, exportTRXTokensProduction, exportJSONTokens } from './TokenRest';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow, Select, MenuItem, InputLabel, Link, Radio, Typography } from '@material-ui/core';
import { Paper, CircularProgress, InputBase, Button, IconButton, Tooltip, Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Popper, Grow, ClickAwayListener, MenuList, Grid, ButtonGroup } from '@material-ui/core';
import { Search, AddCircle, RemoveRedEye, Delete, Edit, Done, Undo, Language, ArrowDropDown, CloudDownload, Check, ArrowUpward, ArrowDownward } from '@material-ui/icons';

import TablePaginationActions from '../components/TablePaginationActions';
import { canIButton, canITransation, transationNextState, nextState, previousState, utilsApps, tokenStates, checkUser, allLangs, newLangs } from '../utils/Utils';
import { getTranslators } from '../translators/TranslatorRest';
// import zIndex from '@material-ui/core/styles/zIndex';

let strings = new LocalizedStrings({
  en: {
    buttonAdd: 'Add new token',
    buttonExportEvolis: 'Export All tokens',
    buttonExportSelected: 'Export Selected tokens',
    buttonExport: 'Export to xlsx',
    buttonNextState: 'Next State',
    buttonPreviousState: 'Previous State',
    buttonImport: 'Import xlsx',
    buttonSubmit: 'Submit',
    buttonUntranslate: 'Untranslate',
    buttonLangs: 'Select Lang',
    buttonTokenReady: 'Tokens are ready',
    buttonDeleteTokens: 'Delete',
    buttonUndoTokens: 'Undo tokens to edit',

    applicationCombo: 'Application',
    stateCombo: 'State',
    devCombo: 'Created by',
    allCombo: 'All',

    toolTipDelete: 'Delete token',
    toolTipEdit: 'Edit token',
    toolTipShow: 'Show token',
    toolTipDone: 'Token is ready',
    toolTipUndo: 'Undo token to edit',
    toolTipTranslate: 'Send to translate',
    toolTipVerify: 'Verify',
    tableHeaderTokenName: 'Token',
    tableHeaderLink: 'Screenshot',
    tableHeaderDescription: 'Description',
    tableHeaderDefaultText: 'Default Text',
    tableHeaderDateCreated: "Creation Date",
    tableHeaderDateModified: "Modified Data",

    tableHeaderState: 'State',
    tableHeaderApp: 'App',
    searchPlaceHolder: 'search',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete token \'{entity}\'?',
    exportDialogTitle: 'Export Confirmation',
    exportVerifiedDialogTitle: 'Export Verified Confirmation',
    exportDevDoneDialogTitle: 'Export Developer Done Confirmation',
    exportStatesDialogText: 'Are you sure that you want to export the tokens?',
    exportAppsDialogText: 'Select the application(s) to export',
    exportDevDoneDialogText: 'Are you sure that you want to export tokens only in \'Dev_Done\' state?',
    exportTranslatorDialogText: 'Are you sure that you want to export all tokens?',
    exportTranslatorPartialDialogText: 'Are you sure that you want to export selected tokens?',
    addTokenToTranslatorDialogTitle: 'Add Tokens to Translator',
    addTokenToTranslatorDialogText: 'Select translator to add tokens',
    exportProductionDialogTitle: 'Export XLSX and TRX for production',
    exportProductionDialogText: 'Are you sure you want to export for production?',
    exportProductionSelectText: 'Select an app to export',

    exportDevelopmentDialogText: 'Are you sure you want to export for development?',
    exportDevelopmentSelectText: 'Select an app to export',

    selectExportLangs: 'Choose languages to export',
    tableHeaderLangs: 'Languages',

    submitDialogTitle: 'Submit Confirmation',
    submitDialogText: 'Are you sure that you want submit to the client?',

    multipleDeleteDialogTitle: 'Delete Confirmation',
    multipleDeleteDialogText: 'Are you sure that you want to delete all selected items ?',

    downloadPopUpDialogTitle: 'Export',
    downloadPopUpDialogText: 'Exporting Tokens',

    importSuccessDialogTitle: 'Import success',
    importSuccessDialogText: 'Import has been successful',

    exportMissingDialogTitle: 'Import error',
    exportMissingDialogText: 'Some tokens didnt exists, see the following file',

    exportEmptyTokensDialogTitle: 'Empty File',
    exportEmptyTokensDialogText: 'One or more files that were exported had no tokens',

    confirm: 'Confirm',
    cancel: 'Cancel',
    ok: 'OK',
    yes: 'Yes',
    no: 'No',
  }
});

function TokenTable(props) {

  const { classes } = props

  const { userType, userExport } = props;
  const { tokenTableForceRefresh } = props;
  const { tokenPage, setTokenPage } = props;
  const { tokenRowsPerPage, setTokenRowsPerPage } = props;
  const { tokenSearch, setTokenSearch } = props;
  const { tokenAppFilter, setTokenAppFilter } = props;
  const { tokenStateFilter, setTokenStateFilter } = props;
  const { tokenDevFilter, setTokenDevFilter } = props;
  const { tokenList } = props;
  const { translatorList } = props;
  const { developersList } = props;
  const { exporting } = props;
  const { importSuccess } = props;
  const { importSuccessInfo } = props;
  const { exportMissing } = props;
  const { exportEmptyTokens } = props;

  const { setDetailOp } = props;

  const [entityToDelete, setEntityToDelete] = React.useState(undefined);
  const [entityToExportEvolis, setEntityToExportEvolis] = React.useState(false);
  const [entityToExportTranslator, setEntityToExportTranslator] = React.useState(false);
  const [entityToExportPartialTranslator, setEntityToExportPartialTranslator] = React.useState(false);
  const [entityToExport, setEntityToExport] = React.useState(undefined);
  const [selectLangs, setSelectLangs] = React.useState(false);
  const [downloadPopUp, setDownloadPopUp] = React.useState(false);
  const [translatorsToAdd, setTranslatorsToAdd] = React.useState(undefined);

  const [search, setSearch] = React.useState(tokenSearch);
  const [selected, setSelected] = React.useState([]);
  const [entityToSubmit, setEntityToSubmit] = React.useState(undefined);

  const [exportCheckboxState, setExportCheckboxState] = React.useState({ checked: false });
  const [exportCheckboxState1, setExportCheckboxState1] = React.useState({ checked: false });
  const [appsToExport, setAppsToExport] = React.useState({ apps: utilsApps.filter(app => (app.view.includes(userType))).map(app1 => { return { checked: false, appId: app1.appId, description: app1.description } }) });
  const [langsToExport, setLangsToExport] = React.useState({ langs: newLangs.map((lang) => { return { checked: false, lang: lang.lang, description: lang.description } }) });
  const [multipleDeleteConfirm, setMultipleDeleteConfirm] = React.useState(undefined);

  const page = tokenPage;
  const rowsPerPage = tokenRowsPerPage;

  const options = ['Export XLSX', 'Export TRX', 'Export XLSX and TRX', 'Export Dev Done XLSX', 'Export Production', 'Export Development', 'Export JSON'];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [stickyToolbar, setStickyToolbar] = React.useState(false);
  const [activeSort, setActiveSort] = React.useState(null);

  const fileUploader = React.useRef(null);

  function setFiltersDefaults() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlFilterDev = urlParams.get('dev');
    const urlFilterState = urlParams.get('state');
    const urlFilterAppId = urlParams.get('application');

    if (urlFilterDev !== null) {
      setTokenDevFilter(urlFilterDev);
    }
    if (urlFilterState !== null) {
      setTokenStateFilter(urlFilterState);
    }
    if (urlFilterAppId !== null) {
      setTokenAppFilter(urlFilterAppId);
    }

  }
  setFiltersDefaults();

  // Reload page on backbutton pressed 
  const popStateProcess = () => {
    window.location.reload();
  }
  window.addEventListener("popstate", popStateProcess);

  useEffect(
    () => {
      getTokens(tokenDevFilter, tokenStateFilter, tokenAppFilter, page * rowsPerPage, rowsPerPage, window.location.search, activeSort);
      setSelected([]);
      if ((checkUser(userType) !== 'TRANSLATOR') && (checkUser(userType) !== 'EVOLIS')) {
        getTranslators(0, 1000);
        getDevelopers();
      }
      const handleScroll = () => {
        if (window.pageYOffset >= 130) {
          setStickyToolbar(true);
        } else {
          setStickyToolbar(false);
        }
      }
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);

    },
    [page, rowsPerPage, tokenSearch, tokenTableForceRefresh, tokenAppFilter, tokenStateFilter, tokenDevFilter, userType, userExport, activeSort]
  );


  const handleChangePage = (event, newPage) => {
    setTokenPage(newPage);
  }

  const handleChangeRowsPerPage = event => {
    setTokenRowsPerPage(parseInt(event.target.value, 10));
  }

  const searchKeyPress = event => {
    if (event.charCode === 13) {//ENTER key pressed
      event.preventDefault();
      setTokenSearch(search);
    }
  }

  const onAdd = () => {
    setDetailOp({ type: 'token', edit: true, value: 0 });
  }
  const onShow = (token) => {
    setDetailOp({ type: 'token', edit: false, value: token.token_id });
  }
  const onEdit = (token) => {
    if ((checkUser(userType) === 'TRANSLATOR') || (checkUser(userType) === 'EVOLIS')) {
      setDetailOp({ type: 'token', edit: false, value: token.token_id });
    }
    else {
      setDetailOp({ type: 'token', edit: true, value: token.token_id });
    }
  }

  const onOperation = (op, token) => {
    if (op === 'UNDO' && token.state === 'VERIFIED') {
      undoFromVerified(token.token_id);
    }
    else if (op === 'UNDO' && (token.state === 'SUBMITED/PT' || token.state === 'SUBMITED/T')) {
      if (token.translator_email === null) {
        var tokenid = [token.token_id];
        setTranslatorsToAdd({ trans: translatorList.items.map((trans) => { return { checked: false, email: trans.email, tokenList: tokenid, tokenState: token.state } }) });
      }
      else {
        setTokenState(token.token_id, transationNextState(op, token.state));
      }
    }
    else if (op === 'SEND_TO_TRANSLATE' && token.state === 'DEV_DONE') {
      if (token.translator_email === null) {
        var tokenid1 = [token.token_id];
        setTranslatorsToAdd({ trans: translatorList.items.map((trans) => { return { checked: false, email: trans.email, tokenList: tokenid1, tokenState: token.state } }) });
      }
      else {
        setTokenState(token.token_id, transationNextState(op, token.state));
      }
    }
    else {
      setTokenState(token.token_id, transationNextState(op, token.state));
    }
  }

  const isFullUrl = (url) => {
    if (url === null || url.trim() === '') {
      return (true);
    }
    return (url.indexOf('https') !== -1);
  }

  const getFileFullUrl = (filename) => {
    return process.env.REACT_APP_WEB_API_URL + "/v1/screenshots/" + filename;
  }

  const onExportEvolis = () => {
    setEntityToExportEvolis(true);
  }

  const onExportTranslator = () => {
    setEntityToExportTranslator(true);
  }

  const onExportTranslatorPartial = () => {
    setEntityToExportPartialTranslator(true);
  }

  const onExport = () => {
    setEntityToExport(options[selectedIndex]);
  }

  const onSelectLangs = () => {
    setSelectLangs(true);
  }


  const onTokensReady = tokenList => {
    for (var i = 0; i < selected.length; i++) {
      for (var l = 0; l < tokenList.items.length; l++) {
        if (selected[i] === tokenList.items[l].token_id) {
          setTokenState(selected[i], nextState(tokenList.items[l].state));
        }
      }
    }
  }

  const onTokenDelete = tokenList => {
    setMultipleDeleteConfirm(true);
  }
  const handleMultipleDeleteClose = (confirm, tokenList = null) => {
    if (confirm && tokenList !== null) {
      setMultipleDeleteConfirm(undefined);
      for (var i = 0; i < selected.length; i++) {
        for (var l = 0; l < tokenList.items.length; l++) {
          if (selected[i] === tokenList.items[l].token_id) {
            deleteToken(tokenList.items[l]);
          }
        }
      }
    } else {
      setMultipleDeleteConfirm(undefined);
    }
  }

  const onUndoTokensToEdit = tokenList => {
    for (var i = 0; i < selected.length; i++) {
      for (var l = 0; l < tokenList.items.length; l++) {
        if (selected[i] === tokenList.items[l].token_id) {
          onOperation('UNDO', tokenList.items[l]);
        }
      }
    }
  }

  const onNextState = tokenList => {
    if (tokenList.items[0].state === 'DEV_DONE') {
      setTranslatorsToAdd({ trans: translatorList.items.map((trans) => { return { checked: false, email: trans.email, tokenList: selected } }) });
    }
    else {
      for (var i = 0; i < selected.length; i++) {
        for (var l = 0; l < tokenList.items.length; l++) {
          if (selected[i] === tokenList.items[l].token_id)
            setTokenState(selected[i], nextState(tokenList.items[l].state));
        }
      }
    }
  }

  const onPreviousState = tokenList => {
    for (var i = 0; i < selected.length; i++) {
      for (var l = 0; l < tokenList.items.length; l++) {
        if (selected[i] === tokenList.items[l].token_id) {
          setTokenState(selected[i], previousState(tokenList.items[l].state));
        }
      }
    }
  }


  const onImportChange = (event) => {
    var reader = new FileReader();
    reader.onload = () => {

    };
    //reader.readAsArrayBuffer(event.target.files[0]);
    importXLSXTokens(event.target.files[0]);

  }

  const handleImportClick = () => {
    document.getElementById('fileInput').click()
  }

  const onSubmit = tokenList => {
    setEntityToSubmit(tokenList);
  }

  const handleSubmitDialogClose = confirm => {
    if (confirm) {
      onNextState(entityToSubmit);
    }
    setEntityToSubmit(undefined);
  }

  const handleDownloadPopUpDialogClose = confirm => {
    setDownloadPopUp(false);
  }

  const handleImportSuccessDialogClose = confirm => {
    store.dispatch(setImportSuccessTokens(false));
  }

  const handleExportMissingDialogClose = confirm => {
    store.dispatch(setExportMissingTokens(false));
    store.dispatch(refreshTokenTable());
  }

  const handleExportEmptyTokensDialogClose = confirm => {
    store.dispatch(setExportEmptyTokens(false));
  }

  const onDelete = token => {
    setEntityToDelete(token);
  }

  const onUntranslate = token => {
    setTokenState(token.token_id, 'PARTIALLY_TRANSLATED');
  }

  const handleDeleteDialogClose = confirm => {
    if (confirm)
      deleteToken(entityToDelete);
    setEntityToDelete(undefined);
  }

  const handleExportEvolisDialogClose = confirm => {
    if (confirm)
      exportEvolisXLSXTokens(langsToExport.langs, appsToExport.apps);
    setEntityToExportEvolis(false);
    setAppsToExport({ apps: utilsApps.filter(app => (app.view.includes(userType))).map(app1 => { return { checked: false, appId: app1.appId, description: app1.description } }) });
    setLangsToExport({ langs: newLangs.map((lang) => { return { checked: false, lang: lang.lang, description: lang.description } }) });
  }

  const handleExportTranslatorDialogClose = confirm => {
    if (confirm)
      exportTranslatorXLSXTokens();
    setEntityToExportTranslator(false);
  }

  const handleExportPartialTranslatorDialogClose = confirm => {
    if (confirm) {
      exportTranslatorXLSXTokens(selected);
      setEntityToExportPartialTranslator(false);
    } else {
      setEntityToExportPartialTranslator(false);
    }
  }


  const handleExportDialogClose = async (confirm) => {
    if (confirm) {

      if (entityToExport === 'Export Production') {

        if (exportCheckboxState1.checked) {
          await exportXLSXTokensProduction(appsToExport.apps, 3);
          await exportTRXTokensProduction(appsToExport.apps, 3);
        }
        else {
          await exportXLSXTokensProduction(appsToExport.apps, 1);
          await exportTRXTokensProduction(appsToExport.apps, 1);
        }

      } else if (entityToExport === 'Export Development') {

        await exportXLSXTokensProduction(appsToExport.apps, 4);
        await exportTRXTokensProduction(appsToExport.apps, 4);

      } else if (entityToExport === 'Export Dev Done XLSX') {

        exportDevDone(appsToExport.apps);

      } else if (entityToExport === 'Export XLSX') {

        if (exportCheckboxState.checked && exportCheckboxState1.checked)
          exportXLSXTokens(3, langsToExport.langs, appsToExport.apps);
        else if (exportCheckboxState.checked)
          exportXLSXTokens(1, langsToExport.langs, appsToExport.apps);
        else if (exportCheckboxState1.checked)
          exportXLSXTokens(2, langsToExport.langs, appsToExport.apps);
        else {
          exportXLSXTokens(0, langsToExport.langs, appsToExport.apps);
        }

      } else if (entityToExport === 'Export TRX') {

        if (exportCheckboxState.checked && exportCheckboxState1.checked)
          exportTRXTokens(3, allLangs(), appsToExport.apps);
        else if (exportCheckboxState.checked)
          exportTRXTokens(1, allLangs(), appsToExport.apps);
        else if (exportCheckboxState1.checked)
          exportTRXTokens(2, allLangs(), appsToExport.apps);
        else {
          exportTRXTokens(0, allLangs(), appsToExport.apps);
        }

      } else if (entityToExport === 'Export JSON') {

        if (exportCheckboxState.checked && exportCheckboxState1.checked)
          exportJSONTokens(3, allLangs(), appsToExport.apps);
        else if (exportCheckboxState.checked)
          exportJSONTokens(1, allLangs(), appsToExport.apps);
        else if (exportCheckboxState1.checked)
          exportJSONTokens(2, allLangs(), appsToExport.apps);
        else {
          exportJSONTokens(0, allLangs(), appsToExport.apps);
        }

      } else {

        if (exportCheckboxState.checked && exportCheckboxState1.checked) {
          await exportXLSXTokens(3, langsToExport.langs, appsToExport.apps);
          await exportTRXTokens(3, allLangs(), appsToExport.apps);
        }
        else if (exportCheckboxState.checked) {
          await exportXLSXTokens(1, langsToExport.langs, appsToExport.apps);
          await exportTRXTokens(1, allLangs(), appsToExport.apps);
        }
        else if (exportCheckboxState1.checked) {
          await exportXLSXTokens(2, langsToExport.langs, appsToExport.apps);
          await exportTRXTokens(2, allLangs(), appsToExport.apps);
        }
        else {
          await exportXLSXTokens(0, langsToExport.langs, appsToExport.apps);
          await exportTRXTokens(0, allLangs(), appsToExport.apps);
        }

      }
      setDownloadPopUp(true);
    }
    setAppsToExport({ apps: utilsApps.map((app) => { return { checked: false, appId: app.appId, description: app.description } }) });
    setLangsToExport({ langs: newLangs.map((lang) => { return { checked: false, lang: lang.lang, description: lang.description } }) });
    setEntityToExport(undefined);
    setExportCheckboxState({ checked: false });
    setExportCheckboxState1({ checked: false });
  }


  const handleSelectLangsDialogClose = confirm => {
    if (confirm) {
      setSelectLangs(false);
    }
    else {
      setLangsToExport({ langs: newLangs.map((lang) => { return { checked: false, lang: lang.lang, description: lang.description } }) });
      setSelectLangs(false);
    }
  }

  const handleAddTokenToTranslatorClose = confirm => {
    var translator = translatorsToAdd.trans.filter(translator => translator.checked);
    if (confirm && translator.length > 0) {
      if (selected.length > 0) {
        for (var i = 0; i < selected.length; i++) {
          for (var l = 0; l < tokenList.items.length; l++) {
            if (selected[i] === tokenList.items[l].token_id)
              setTokenState(selected[i], nextState(tokenList.items[l].state));
          }
        }
      }
      else {
        setTokenState(translatorsToAdd.trans[0].tokenList, nextState(translatorsToAdd.trans[0].tokenState));
      }
      setTokenTranslator(translatorsToAdd);
    }
    setTranslatorsToAdd(undefined);
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = tokenList.items.map(n => n.token_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }

  const sortHandler = (sort) => {
    var orderStr = null;
    if (activeSort === null) {
      orderStr = sort + '_ASC';
    } else {
      if (activeSort.indexOf(sort) !== -1) {
        const sortCtrArray = activeSort.split("_");
        if (sortCtrArray[1] === 'DESC') {
          orderStr = sort + '_ASC';
        } else {
          orderStr = sort + '_DESC';
        }
      } else {
        orderStr = sort + '_ASC';
      }
    }

    if (orderStr !== null) {
      setActiveSort(orderStr);
    } else {
      setActiveSort(null);
    }

  }

  const handleClick = (event, token_id) => {
    const selectedIndex = selected.indexOf(token_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, token_id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  const isSelected = token_id => selected.indexOf(token_id) !== -1;

  function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
      return uri + separator + key + "=" + value;
    }
  }

  const handleAppSelectChange = event => {
    const newUrl = updateQueryStringParameter(window.location.href, "application", event.target.value);
    window.history.pushState("", "", newUrl);
    setTokenAppFilter(event.target.value);
  };

  const handleStateSelectChange = event => {
    const newUrl = updateQueryStringParameter(window.location.href, "state", event.target.value);
    window.history.pushState("", "", newUrl);
    setTokenStateFilter(event.target.value);
  };

  const handleDevSelectChange = event => {
    const newUrl = updateQueryStringParameter(window.location.href, "dev", event.target.value);
    window.history.pushState("", "", newUrl);
    setTokenDevFilter(event.target.value);
  };

  const handleExportCheckboxChange = exportCheckboxState => {
    exportCheckboxState.checked = !exportCheckboxState.checked;
    setExportCheckboxState({ checked: exportCheckboxState.checked })
  };

  const handleExportCheckboxChange1 = exportCheckboxState1 => {
    exportCheckboxState1.checked = !exportCheckboxState1.checked;
    setExportCheckboxState1({ checked: exportCheckboxState1.checked })
  };

  const handleAppsChange = app => {
    app.checked = !app.checked;
    setAppsToExport({ ...appsToExport, apps: appsToExport.apps });
  };

  const handleAppsProductionChange = app => {
    var newApp = { apps: utilsApps.map((app) => { return { checked: false, appId: app.appId, description: app.description } }) };
    newApp.apps[app.appId - 1].checked = true;
    setAppsToExport({ ...newApp, apps: newApp.apps });
  };

  const handleLangsChange = lang => {
    lang.checked = !lang.checked;
    setLangsToExport({ ...langsToExport, langs: langsToExport.langs });
  };

  const handleTransChange = trans => {
    trans.checked = !trans.checked;
    for (var x = 0; x < translatorsToAdd.trans.length; x++) {
      if (translatorsToAdd.trans[x].email !== trans.email)
        translatorsToAdd.trans[x].checked = false;
    }
    setTranslatorsToAdd({ ...translatorsToAdd, trans: translatorsToAdd.trans });
  };


  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.containerDashboard} >
      <Paper className={classes.workarea}>
        <div className={classes.flexHeader + ' ' + (stickyToolbar ? classes.stickyToolbar : '')}>

          <div className={classes.filtersHolder}>
            {canIButton('APP_LIST', userType) &&
              <FormControl className={classes.formControl}>
                <InputLabel>{strings.applicationCombo}</InputLabel>
                <Select value={tokenAppFilter} onChange={handleAppSelectChange}>
                  <MenuItem value={0}><em>{strings.allCombo}</em></MenuItem>
                  {utilsApps.filter(app => (app.view.includes(userType))).map(app1 => (
                    <MenuItem key={app1.appId} value={app1.appId}>{app1.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
            {canIButton('STATE_LIST', userType) &&
              <FormControl className={classes.formControl}>
                <InputLabel>{strings.stateCombo}</InputLabel>
                <Select value={tokenStateFilter} onChange={handleStateSelectChange} >
                  <MenuItem value={0}><em>{strings.allCombo}</em></MenuItem>
                  {tokenStates.filter(token => (token.view.includes(userType))).map(token1 => (
                    <MenuItem key={token1.name} value={token1.name}>{token1.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
            {canIButton('DEV_LIST', userType) && developersList !== undefined &&
              <FormControl className={classes.formControl}>
                <InputLabel>{strings.devCombo}</InputLabel>
                <Select value={tokenDevFilter} onChange={handleDevSelectChange} >
                  <MenuItem value={0}><em>{strings.allCombo}</em></MenuItem>
                  {developersList.map(dev =>
                    <MenuItem key={dev.created_by} value={dev.created_by}>{dev.created_by}</MenuItem>
                  )}
                </Select>
              </FormControl>
            }

          </div>

          <div className={classes.BtnsHolder}>

            {canIButton('ADD_TOKEN', userType) &&
              <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => onAdd()}>
                <AddCircle className={classes.leftIcon} />
                {strings.buttonAdd}
              </Button>
            }
            {canIButton('EXPORT_EVOLIS', userType) &&
              <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => onExportEvolis()}>
                <CloudDownload className={classes.leftIcon} />
                {strings.buttonExportEvolis}
              </Button>
            }

            {canIButton('EXPORT_TRANSLATOR', userType) && userExport &&
              <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => onExportTranslator()}>
                <CloudDownload className={classes.leftIcon} />
                {strings.buttonExportEvolis}
              </Button>
            }

            {canIButton('EXPORT_TRANSLATOR_PARTIAL', userType) && userExport &&
              <Button variant="contained" color="secondary" disabled={selected.length === 0} className={classes.flexButton} onClick={() => onExportTranslatorPartial()}>
                <CloudDownload className={classes.leftIcon} />
                {strings.buttonExportSelected}
              </Button>
            }

            {canIButton('TOKENS_READY', userType) && tokenStateFilter === 'OPEN' &&
              <Button variant="contained" color="secondary" disabled={selected.length === 0} className={classes.flexButton} onClick={() => onTokensReady(tokenList)}>
                <Check className={classes.leftIcon} />
                {strings.buttonTokenReady}
              </Button>
            }

            {canIButton('TOKENS_DELETE', userType) && tokenStateFilter === 'OPEN' &&
              <Button variant="contained" color="secondary" disabled={selected.length === 0} className={classes.flexButton} onClick={() => onTokenDelete(tokenList)}>
                <Delete className={classes.leftIcon} />
                {strings.buttonDeleteTokens}
              </Button>
            }

            {canIButton('TOKENS_UNDO_TO_EDIT', userType) && tokenStateFilter === 'DEV_DONE' &&
              <Button variant="contained" color="secondary" disabled={selected.length === 0} className={classes.flexButton} onClick={() => onUndoTokensToEdit(tokenList)}>
                <Undo className={classes.leftIcon} />
                {strings.buttonUndoTokens}
              </Button>
            }

            {canIButton('PREVIOUS_STATE', userType) && tokenStateFilter !== 'VERIFIED' && tokenStateFilter !== 'OPEN' && tokenStateFilter !== '0' &&
              <Button variant="contained" color="secondary" disabled={selected.length === 0} className={classes.flexButton} onClick={() => onPreviousState(tokenList)}>
                {strings.buttonPreviousState}
              </Button>
            }
            {canIButton('NEXT_STATE', userType) && tokenStateFilter !== 'VERIFIED' && tokenStateFilter !== '0' &&
              <Button variant="contained" color="secondary" disabled={selected.length === 0} className={classes.flexButton} onClick={() => onNextState(tokenList)}>
                {strings.buttonNextState}
              </Button>
            }
            {canIButton('EXPORT', userType) &&
              <Grid item xs={"auto"} className={classes.flexButton} >
                <ButtonGroup variant="contained" color="secondary" style={{ zIndex: 9 }} ref={anchorRef} aria-label="split button">
                  <Button onClick={onExport} >{options[selectedIndex]} </Button>
                  <Button
                    color="secondary"
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDown />
                  </Button>
                </ButtonGroup>
                <Popper style={{ zIndex: 9 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu">
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={event => handleMenuItemClick(event, index)}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            }
            {exporting &&
              <CircularProgress size={30} />
            }
            {canIButton('SUBMIT', userType) && (tokenStateFilter === 'PARTIALLY_TRANSLATED' || tokenStateFilter === 'TRANSLATED') &&
              <Button variant="contained" color="secondary" disabled={selected.length === 0} className={classes.flexButton} onClick={() => onSubmit(tokenList)}>
                {strings.buttonSubmit}
              </Button>
            }
            {canIButton('IMPORT', userType) &&
              <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => handleImportClick()}>
                {strings.buttonImport}
                <input id="fileInput" type="file" accept='.xlsx' ref={fileUploader} style={{ display: "none" }} onChange={(file) => { onImportChange(file) }} />
              </Button>
            }
            {canIButton('IMPORT_TRANSLATOR', userType) && userExport &&
              <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => handleImportClick()}>
                {strings.buttonImport}
                <input id="fileInput" type="file" accept='.xlsx' ref={fileUploader} style={{ display: "none" }} onChange={(file) => { onImportChange(file) }} />
              </Button>
            }
            {/* <div className={ classes.flexExpand }/> */}
            <Paper className={classes.searchPaper}>
              <InputBase
                className={classes.searchInput}
                placeholder={strings.searchPlaceHolder}
                inputProps={{ 'aria-label': 'Search Text' }}
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                onKeyPress={(event) => searchKeyPress(event)} />
              <IconButton aria-label='Search' size='small' onClick={() => setTokenSearch(search)}>
                <Search />
              </IconButton>
            </Paper>
          </div>

        </div>

        <div className={classes.tableArea} >
          {tokenList !== undefined &&
            <Table size='small'>
              <TableHead>
                <TableRow>
                  {canIButton('NEXT_STATE', userType) && tokenStateFilter !== 'VERIFIED' && tokenStateFilter !== '0' &&
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={selected.length > 0 && selected.length < tokenList.items.length}
                        checked={selected.length > 0 && selected.length === tokenList.items.length}
                        onChange={(event) => handleSelectAllClick(event)}
                        color='primary' />
                    </TableCell>
                  }
                  {canIButton('SUBMIT', userType) && (tokenStateFilter === 'PARTIALLY_TRANSLATED' || tokenStateFilter === 'TRANSLATED') &&
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={selected.length > 0 && selected.length < tokenList.items.length}
                        checked={selected.length > 0 && selected.length === tokenList.items.length}
                        onChange={(event) => handleSelectAllClick(event)}
                        color='primary' />
                    </TableCell>
                  }

                  {((canIButton('TOKENS_READY', userType) && tokenStateFilter === 'OPEN') ||
                    (canIButton('TOKENS_DELETE', userType) && tokenStateFilter === 'OPEN') ||
                    (canIButton('TOKENS_UNDO_TO_EDIT', userType) && tokenStateFilter === 'DEV_DONE')) &&
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={selected.length > 0 && selected.length < tokenList.items.length}
                        checked={selected.length > 0 && selected.length === tokenList.items.length}
                        onChange={(event) => handleSelectAllClick(event)}
                        color='primary' />
                    </TableCell>
                  }

                  <TableCell className={classes.tableHeaderCell} onClick={() => sortHandler('TOKEN')}>
                    {strings.tableHeaderTokenName}
                    <ArrowUpward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'TOKEN_ASC') ? {} : { display: "none" }} />
                    <ArrowDownward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'TOKEN_DESC') ? {} : { display: "none" }} />
                  </TableCell>

                  <TableCell className={classes.tableHeaderCell} onClick={() => sortHandler('STATE')}>
                    {strings.tableHeaderState}
                    <ArrowUpward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'STATE_ASC') ? {} : { display: "none" }} />
                    <ArrowDownward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'STATE_DESC') ? {} : { display: "none" }} />
                  </TableCell>

                  <TableCell className={classes.tableHeaderCell} onClick={() => sortHandler('SCRSHT')}>
                    {strings.tableHeaderLink}
                    <ArrowUpward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'SCRSHT_ASC') ? {} : { display: "none" }} />
                    <ArrowDownward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'SCRSHT_DESC') ? {} : { display: "none" }} />
                  </TableCell>

                  <TableCell className={classes.tableHeaderCell} onClick={() => sortHandler('DESC')}>
                    {strings.tableHeaderDescription}
                    <ArrowUpward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'DESC_ASC') ? {} : { display: "none" }} />
                    <ArrowDownward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'DESC_DESC') ? {} : { display: "none" }} />
                  </TableCell>

                  <TableCell className={classes.tableHeaderCell} onClick={() => sortHandler('DEFTXT')}>
                    {strings.tableHeaderDefaultText}
                    <ArrowUpward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'DEFTXT_ASC') ? {} : { display: "none" }} />
                    <ArrowDownward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'DEFTXT_DESC') ? {} : { display: "none" }} />
                  </TableCell>

                  <TableCell className={classes.tableHeaderCell} onClick={() => sortHandler('DATECREATED')}>
                    {strings.tableHeaderDateCreated}
                    <ArrowUpward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'DATECREATED_ASC') ? {} : { display: "none" }} />
                    <ArrowDownward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'DATECREATED_DESC') ? {} : { display: "none" }} />
                  </TableCell>

                  <TableCell className={classes.tableHeaderCell} onClick={() => sortHandler('DATEMOD')}>
                    {strings.tableHeaderDateModified}
                    <ArrowUpward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'DATEMOD_ASC') ? {} : { display: "none" }} />
                    <ArrowDownward className={classes.tableHeaderCellOrderIcon} style={(activeSort === 'DATEMOD_DESC') ? {} : { display: "none" }} />
                  </TableCell>

                  <TableCell colSpan={99} />
                </TableRow>
              </TableHead>
              <TableBody>
                {tokenList.items.map(token => {

                  const isItemSelected = isSelected(token.token_id);
                  return (
                    <TableRow key={token.token_id}>
                      {/* { canIButton('NEXT_STATE', userType) && ( tokenStateFilter === 'DEV_DONE' || tokenStateFilter === 'TRANSLATED') && */}
                      {canIButton('NEXT_STATE', userType) && tokenStateFilter !== 'VERIFIED' && tokenStateFilter !== '0' &&
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onClick={event => handleClick(event, token.token_id)} color='primary' />
                        </TableCell>
                      }

                      {canIButton('EXPORT_TRANSLATOR_PARTIAL', userType) && (tokenStateFilter === 'PARTIALLY_TRANSLATED' || tokenStateFilter === 'TRANSLATED') && 
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onClick={event => handleClick(event, token.token_id)} color='primary' />
                        </TableCell>
                      }

                      {canIButton('SUBMIT', userType) && (tokenStateFilter !== 'PARTIALLY_TRANSLATED' && tokenStateFilter !== 'TRANSLATED') &&
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onClick={event => handleClick(event, token.token_id)} color='primary' />
                        </TableCell>
                      }

                      {((canIButton('TOKENS_READY', userType) && tokenStateFilter === 'OPEN') ||
                        (canIButton('TOKENS_DELETE', userType) && tokenStateFilter === 'OPEN') ||
                        (canIButton('TOKENS_UNDO_TO_EDIT', userType) && tokenStateFilter === 'DEV_DONE')) &&
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onClick={event => handleClick(event, token.token_id)} color='primary' />
                        </TableCell>
                      }

                      {<TableCell >{token.token}</TableCell>}
                      {<TableCell >{tokenStates.filter(token2 => token2.name === token.state)[0].description}</TableCell>}

                      {(isFullUrl(token.link)) &&
                        <TableCell style={{ maxWidth: 120, wordWrap: 'break-word' }} > <Link target='_blank' href={token.link}  >{token.link}</Link></TableCell>
                      }
                      {(!isFullUrl(token.link)) &&
                        <TableCell style={{ maxWidth: 120, wordWrap: 'break-word' }}> <Link target='_blank' href={getFileFullUrl(token.link)} >{getFileFullUrl(token.link)}</Link></TableCell>
                      }

                      {<TableCell >{token.description}</TableCell>}
                      {<TableCell >{token.default_text}</TableCell>}
                      {<TableCell >{formatDate(new Date(token.create_date))}</TableCell>}
                      {<TableCell >{formatDate(new Date(token.update_date))}</TableCell>}

                      {!canITransation('UNDO', userType, token.state) && <TableCell />}
                      {canITransation('UNDO', userType, token.state) &&
                        <TableCell className={classes.tableButton} >
                          <Tooltip title={strings.toolTipUndo}>
                            <IconButton size='small' onClick={() => onOperation('UNDO', token)} >
                              <Undo />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      }

                      {!canITransation('DONE', userType, token.state) &&
                        !canITransation('SEND_TO_TRANSLATE', userType, token.state) &&
                        !canITransation('VERIFY', userType, token.state) &&
                        !canITransation('UNTRANSLATE', userType, token.state) &&
                        <TableCell />}
                      {canITransation('DONE', userType, token.state) &&
                        <TableCell className={classes.tableButton} >
                          <Tooltip title={strings.toolTipDone}>
                            <IconButton size='small' onClick={() => onOperation('DONE', token)} >
                              <Done />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      }

                      {canITransation('SEND_TO_TRANSLATE', userType, token.state) &&
                        <TableCell className={classes.tableButton} >
                          <Tooltip title={strings.toolTipTranslate}>
                            <IconButton size='small' onClick={() => onOperation('SEND_TO_TRANSLATE', token)} >
                              <Language />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      }

                      {canITransation('VERIFY', userType, token.state) &&
                        <TableCell className={classes.tableButton} >
                          <Tooltip title={strings.toolTipVerify}>
                            <IconButton size='small' onClick={() => onOperation('VERIFY', token)}>
                              <Done />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      }

                      {canITransation('UNTRANSLATE', userType, token.state) &&
                        <TableCell className={classes.tableButton} >
                          <Button variant='contained' color='secondary' className={classes.lineButton} type='submit' onClick={() => onUntranslate(token)}>
                            {strings.buttonUntranslate}
                          </Button>
                        </TableCell>
                      }

                      {!canITransation('SHOW', userType, token.state) &&
                        !canITransation('EDIT_TOKEN', userType, token.state) &&
                        <TableCell />}
                      {canITransation('SHOW', userType, token.state) &&
                        <TableCell className={classes.tableButton} >
                          <Tooltip title={strings.toolTipShow}>
                            <IconButton size='small' onClick={() => onShow(token)} >
                              <RemoveRedEye />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      }
                      {canITransation('EDIT_TOKEN', userType, token.state) &&
                        <TableCell className={classes.tableButton} >
                          <Tooltip title={strings.toolTipEdit}>
                            <IconButton size='small' onClick={() => onEdit(token)} >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      }

                      {!canITransation('DELETE', userType, token.state) && <TableCell />}
                      {canITransation('DELETE', userType, token.state) &&
                        <TableCell className={classes.tableButton} >
                          <Tooltip title={strings.toolTipDelete}>
                            <IconButton size='small' onClick={() => onDelete(token)} >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      }
                    </TableRow>
                  )
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={[10, 20, 40]} colSpan={99} count={tokenList.count} rowsPerPage={rowsPerPage} page={page}
                    SelectProps={{ inputProps: { 'aria-label': 'Rows per page' }, native: true, }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          }
          {tokenList === undefined &&
            <CircularProgress className={classes.spinner} />
          }
        </div>
      </Paper>
      <Dialog open={entityToDelete !== undefined} onClose={() => handleDeleteDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.deleteDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.deleteDialogText.replace('{entity}', entityToDelete !== undefined ? entityToDelete.token : '')}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleDeleteDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={entityToExportEvolis} onClose={() => handleExportEvolisDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.exportDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.exportAppsDialogText}</DialogContentText>
        </DialogContent>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.tableHeaderCell}>{strings.tableHeaderApps}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appsToExport.apps.map((app, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCellNoBorder} padding="checkbox" onClick={() => handleAppsChange(app)} >
                  <Checkbox checked={app.checked} color='primary' />
                </TableCell>
                <TableCell className={classes.tableCellNoBorder}>{app.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleExportEvolisDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleExportEvolisDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
          <Button className={classes.lineButton} onClick={() => onSelectLangs()} variant='contained' color='secondary'>{strings.buttonLangs}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={entityToExportTranslator} onClose={() => handleExportTranslatorDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.exportDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.exportTranslatorDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleExportTranslatorDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleExportTranslatorDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={entityToExportPartialTranslator} onClose={() => handleExportPartialTranslatorDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.exportDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.exportTranslatorPartialDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleExportPartialTranslatorDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleExportPartialTranslatorDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={entityToExport !== undefined && entityToExport !== 'Export Dev Done XLSX' && entityToExport !== 'Export Production' && entityToExport !== 'Export Development'} onClose={() => handleExportDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.exportVerifiedDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.exportStatesDialogText}</DialogContentText>
        </DialogContent>
        <FormControlLabel
          style={{ justifyContent: 'center' }}
          control={
            <Checkbox
              checked={exportCheckboxState.checked}
              onClick={() => handleExportCheckboxChange(exportCheckboxState)}
            />
          }
          label="Export Submited"
        />
        <FormControlLabel
          style={{ justifyContent: 'center' }}
          control={
            <Checkbox
              checked={exportCheckboxState1.checked}
              onClick={() => handleExportCheckboxChange1(exportCheckboxState1)}
            />
          }
          label="Export Developer Done"
        />
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.tableHeaderCell}>{strings.tableHeaderApps}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appsToExport.apps.map((app, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCellNoBorder} padding="checkbox" onClick={() => handleAppsChange(app)} >
                  <Checkbox checked={app.checked} color='primary' />
                </TableCell>
                <TableCell className={classes.tableCellNoBorder}>{app.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleExportDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleExportDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
          <Button className={classes.lineButton} disabled={entityToExport !== 'Export XLSX'} onClick={() => onSelectLangs()} variant='contained' color='secondary'>{strings.buttonLangs}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={entityToExport === 'Export Dev Done XLSX'} onClose={() => handleExportDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.exportDevDoneDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.exportDevDoneDialogText}</DialogContentText>
        </DialogContent>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.tableHeaderCell}>{strings.tableHeaderApps}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appsToExport.apps.map((app, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCellNoBorder} padding="checkbox" onClick={() => handleAppsChange(app)} >
                  <Checkbox checked={app.checked} color='primary' />
                </TableCell>
                <TableCell className={classes.tableCellNoBorder}>{app.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleExportDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleExportDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={entityToExport === 'Export Production'} onClose={() => handleExportDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.exportProductionDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.exportProductionDialogText}</DialogContentText>
          <DialogContentText>{strings.exportProductionSelectText}</DialogContentText>
        </DialogContent>
        <FormControlLabel
          style={{ justifyContent: 'center' }}
          control={
            <Checkbox
              checked={exportCheckboxState1.checked}
              onClick={() => handleExportCheckboxChange1(exportCheckboxState1)}
            />
          }
          label="Export Developer Done"
        />
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.tableHeaderCell}>{strings.tableHeaderApps}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appsToExport.apps.map((app, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCellNoBorder} padding="checkbox" onClick={() => handleAppsProductionChange(app)} >
                  <Checkbox checked={app.checked} color='primary' />
                </TableCell>
                <TableCell className={classes.tableCellNoBorder}>{app.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleExportDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleExportDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={entityToExport === 'Export Development'} onClose={() => handleExportDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.exportProductionDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.exportDevelopmentDialogText}</DialogContentText>
          <DialogContentText>{strings.exportDevelopmentSelectText}</DialogContentText>
        </DialogContent>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.tableHeaderCell}>{strings.tableHeaderApps}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appsToExport.apps.map((app, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCellNoBorder} padding="checkbox" onClick={() => handleAppsProductionChange(app)} >
                  <Checkbox checked={app.checked} color='primary' />
                </TableCell>
                <TableCell className={classes.tableCellNoBorder}>{app.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleExportDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleExportDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={selectLangs} onClose={() => handleSelectLangsDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.selectExportLangs}</DialogTitle>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.tableHeaderCell}>{strings.tableHeaderLangs}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {langsToExport.langs.map((lang, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCellNoBorder} padding="checkbox" onClick={() => handleLangsChange(lang)} >
                  <Checkbox checked={lang.checked} color='primary' />
                </TableCell>
                <TableCell className={classes.tableCellNoBorder}>{lang.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleSelectLangsDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.confirm}</Button>
          <Button className={classes.lineButton} onClick={() => handleSelectLangsDialogClose(false)} variant='contained' color='secondary'>{strings.cancel}</Button>
        </DialogActions>
      </Dialog>
      {translatorsToAdd !== undefined &&
        <Dialog open={translatorsToAdd !== undefined} onClose={() => handleAddTokenToTranslatorClose(false)}>
          <DialogTitle className={classes.title}>{strings.addTokenToTranslatorDialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{strings.addTokenToTranslatorDialogText}</DialogContentText>
          </DialogContent>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className={classes.tableHeaderCell}>{strings.tableHeaderApps}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {translatorsToAdd.trans.map((trans, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCellNoBorder} padding="checkbox" onClick={() => handleTransChange(trans)} >
                    <Radio checked={trans.checked} color='primary' />
                  </TableCell>
                  <TableCell className={classes.tableCellNoBorder}>{trans.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <DialogActions className={classes.lineButtonBox}>
            <Button className={classes.lineButton} onClick={() => handleAddTokenToTranslatorClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
            <Button className={classes.lineButton} onClick={() => handleAddTokenToTranslatorClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
          </DialogActions>
        </Dialog>
      }
      <Dialog open={entityToSubmit !== undefined} onClose={() => handleSubmitDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.submitDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.submitDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleSubmitDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleSubmitDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={downloadPopUp} onClose={() => handleDownloadPopUpDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.downloadPopUpDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.downloadPopUpDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleDownloadPopUpDialogClose(false)} variant='contained' color='secondary'>{strings.ok}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={importSuccess} onClose={() => handleImportSuccessDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.importSuccessDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.importSuccessDialogText}</DialogContentText>
          {importSuccessInfo.map((importSuccessInfoItem, index) =>
            <Typography key={index} variant="body1">
              <b>{importSuccessInfoItem[0]}</b> {importSuccessInfoItem[1]}
            </Typography>
          )}
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleImportSuccessDialogClose(false)} variant='contained' color='secondary'>{strings.ok}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={exportMissing} onClose={() => handleExportMissingDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.exportMissingDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.exportMissingDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleExportMissingDialogClose(false)} variant='contained' color='secondary'>{strings.ok}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={exportEmptyTokens} onClose={() => handleExportEmptyTokensDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.exportEmptyTokensDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.exportEmptyTokensDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleExportEmptyTokensDialogClose(false)} variant='contained' color='secondary'>{strings.ok}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={multipleDeleteConfirm !== undefined} onClose={() => handleMultipleDeleteClose(false)}>
        <DialogTitle className={classes.title}>{strings.multipleDeleteDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.multipleDeleteDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleMultipleDeleteClose(true, tokenList)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleMultipleDeleteClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userType: state.userType,
    userExport: state.userExport,
    tokenTableForceRefresh: state.tokenTableForceRefresh,
    tokenPage: state.tokenPage,
    tokenRowsPerPage: state.tokenRowsPerPage,
    tokenSearch: state.tokenSearch,
    tokenAppFilter: state.tokenAppFilter,
    tokenStateFilter: state.tokenStateFilter,
    tokenDevFilter: state.tokenDevFilter,
    tokenList: state.tokenList,
    translatorList: state.translatorList,
    developersList: state.developersList,
    exporting: state.exporting,
    importSuccess: state.importSuccess,
    importSuccessInfo: state.importSuccessInfo,
    exportMissing: state.exportMissing,
    exportEmptyTokens: state.exportEmptyTokens,
  }
};

export default connect(mapStateToProps, { setTokenPage, setTokenRowsPerPage, setTokenSearch, setTokenAppFilter, setTokenStateFilter, setTokenDevFilter, setDetailOp, setExportingTokens, setExportEmptyTokens, setImportSuccessTokens, setExportMissingTokens })(withStyles(gStyles)(TokenTable));