import React, { useEffect, useRef } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { setDetailOp, setTokenSelected, refreshTokenTable } from '../utils/Reducer';
import { getToken, createToken, setToken, setTokenTranslations } from './TokenRest';
import { gStyles } from '../utils/Styles';
import { utilsApps, checkUser, canITransation, canIButton, getLangDescription } from '../utils/Utils';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableRow, TableCell, TableBody, Radio, Fab } from '@material-ui/core';
import { CircularProgress, TextField, Button, Grid, Checkbox, Paper, Typography, IconButton } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { Close, CallSplit, Add, Visibility } from '@material-ui/icons';

let strings = new LocalizedStrings({
  en: {

    buttonDuplicate: 'Duplicate token',

    defaultTokenName: 'new token',
    labelName: 'Token name',
    labelLink: 'Screenshot (URL or Upload Image)',
    labelLinkFileUpload: 'Upload Image',
    labelDescription: 'Description',
    labelDefaultText: 'Default Text',
    tableHeaderApps: 'Apps',
    tableHeaderTranslators: 'Translators',
    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',
    toolTipEdit: 'Edit translation',

    translationsTitle: 'Translations',
    tableHeaderLang: 'Language',
    tableHeaderState: 'State',
    tableHeaderTranslation: 'Translation',
    titleTranslation: 'New Translation',

    duplicateDialogTitle: 'Duplicate Confirmation',
    duplicateDialogText: 'Select app for new token',
    duplicateDialogWarning: 'This token has \'{duplicates}\' duplicates',

    duplicateDialogErrorTitle: 'Error duplicating token',
    duplicateDialogErrorText: 'This token has been associated with all apps',


    editAppsDialogTitle: 'Edit Confirmation',
    editDialogText: 'Apps changed, are you sure you want to edit this token?',
    submitDialogTitle: 'Submit Confirmation',
    submitDialogText: 'Are you sure that you want to send this token to the client?',

    exitWithoutSavingDialogTitle: 'Close Without saving',
    exitWithoutSavingDialogText: 'Are you sure that you want to close without saving data?',

    yes: 'Yes',
    no: 'No',
    ok: 'OK',
  }
});

function TokenDetail(props) {

  const { classes } = props;

  const [entityToDuplicateError, setEntityToDuplicateError] = React.useState(false);
  const [entityToDuplicate, setEntityToDuplicate] = React.useState(undefined);
  const [entityToEdit, setEntityToEdit] = React.useState(undefined);

  const [duplicateApps, setDuplicateApps] = React.useState({ apps1: utilsApps.map((app) => { return { disabled: false, checked: false, appId: app.appId, description: app.description } }) });
  const [tokenApps, setTokenApps] = React.useState([]);
  const [entityToSubmit, setEntityToSubmit] = React.useState(undefined);
  const [translationsIds, setTranslationsIds] = React.useState([]);
  const [unSavedCloseConfirm, setUnSavedCloseConfirm] = React.useState(undefined);

  const { userType } = props;
  const { tokenDetailForceRefresh } = props;
  const { tokenSelected, setTokenSelected } = props;
  const { detailValue, detailEdit, setDetailOp } = props;
  const { translatorList } = props;
  const tokenForm = React.useRef();

  const [values, setValues] = React.useState({
    valid: false,
    token_id: 0,
    token: '',
    link: '',
    description: '',
    default_text: '',
    apps: [],
    translators: [],
    translations: [],
    duplicates: 0,
    duplicatesApps: []
  });
  const [errors, setErrors] = React.useState([]);
  const [haveChanges, setHaveChanges] = React.useState(false);
  const [stickyToolbar, setStickyToolbar] = React.useState(false);
  const toolbalHolder = useRef();
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [isFilePicked, setIsFilePicked] = React.useState(false);
  const [imagePreview, setImagePreview] = React.useState(false);

  useEffect(
    () => {
      if (detailValue !== 0)
        getToken();
      else {
        setTokenSelected({
          token_id: 0,
          token: strings.defaultTokenName,
          link: '',
          description: '',
          default_text: '',
          apps: utilsApps.map((app) => { return { checked: false, appId: app.appId, description: app.description } }),
          translators: translatorList.items.map((trans) => { return { checked: false, email: trans.email } }),
          translations: [],
          duplicates: 0,
          duplicatesApps: [],
        });
      }
      const handleScroll = () => {

        if (window.pageYOffset >= 100) {
          setStickyToolbar(true);
        } else {
          setStickyToolbar(false);
        }
        // console.log(toolbalHolder.current.offsetTop); 
      }
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
      
    },
    [detailValue, setTokenSelected, tokenDetailForceRefresh, translatorList]
  );

  if (tokenSelected !== undefined && !values.valid) {
    setValues({
      valid: true,
      token_id: tokenSelected.token_id,
      token: tokenSelected.token,
      link: tokenSelected.link,
      description: tokenSelected.description,
      default_text: tokenSelected.default_text,
      apps: tokenSelected.apps,
      translators: tokenSelected.translators,
      translations: tokenSelected.translations,
      duplicates: tokenSelected.duplicates,
      duplicatesApps: tokenSelected.duplicatesApps,
    });
    var appsArray = [];
    for (var x = 0; x < tokenSelected.apps.length; x++) {
      if (tokenSelected.apps[x].checked) {
        appsArray += tokenSelected.apps[x].appId;
        duplicateApps.apps1[x].disabled = true;
        setDuplicateApps({ ...duplicateApps, apps1: duplicateApps.apps1 });
      }
    }
    for (var y = 0; y < tokenSelected.duplicatesApps.length; y++) {
      if (duplicateApps?.apps1[tokenSelected.duplicatesApps[y].app_id - 1] !== undefined) {
        duplicateApps.apps1[tokenSelected.duplicatesApps[y].app_id - 1].disabled = true;
        setDuplicateApps({ ...duplicateApps, apps1: duplicateApps.apps1 });
      }
    }
    setTokenApps(appsArray);
  }

  const onDuplicate = () => {
    var y = 0;
    for (var x = 0; x < duplicateApps.apps1.length; x++) {
      if (duplicateApps.apps1[x].disabled === false) {
        setEntityToDuplicate({ ...values, apps: duplicateApps.apps1 });
        break;
      }
      y++
    }

    if (y === duplicateApps.apps1.length)
      setEntityToDuplicateError(true);
  }

  function validateForm() {
    const errors = [];

    if (values.token.length === 0) {
      errors.push("Token can't be empty");
    }
    if (values.default_text.length === 0) {
      errors.push("Default text can't be empty");
    }
    if (values.description.length === 0) {
      errors.push("Description can't be empty");
    }

    var selectedApps = 0;
    for (var x = 0; x < values.apps.length; x++) {
      if (values.apps[x].checked === true) {
        selectedApps++;
      }
    }

    if (selectedApps === 0) {
      errors.push("You must associate the new token with one or more apps.");
    }

    return errors;
  }

  function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  const submitForm = (event) => {
    const errors = validateForm();
    if (errors.length > 0) {
      setErrors(errors);
    } else {
      onConfirm(event);
    }
  }

  const getFileFullUrl = (filename) => {
    return process.env.REACT_APP_WEB_API_URL + "/v1/screenshots/" + filename;
  }

  const fileChangeHandler = (event) => {

    const errors = [];

    const fileSize = event.target.files[0].size;
    const fileSizeMb = fileSize / 1024 / 1024;

    if (fileSizeMb < 1) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
      setHaveChanges(true);
      setValues({ ...values, link: event.target.files[0].name });
      setImagePreview(URL.createObjectURL(event.target.files[0]));
      setErrors([]);
    } else {
      errors.push("Max upload size 1 MB. Your file have " + bytesToSize(fileSize));
      setErrors(errors);
    }

  };

  const handleEntityToDuplicateErrorClose = confirm => {
    setEntityToDuplicateError(false);
  }

  const handleDuplicateAppsChange = app => {
    app.checked = !app.checked;
    setEntityToDuplicate({ ...entityToDuplicate, apps: entityToDuplicate.apps });
  };

  const handleDuplicateDialogClose = confirm => {
    if (confirm) {
      createToken(entityToDuplicate);
    }
    else {
      setEntityToDuplicate(undefined);
    }
    onClose();
  }

  const handleChange = fieldName => event => {
    setHaveChanges(true);
    setValues({ ...values, [fieldName]: event.target.value });
  };

  const onClose = (saved = false) => {
    if (haveChanges === true && saved === false) {
      setUnSavedCloseConfirm(true);
    } else {
      setDetailOp(undefined);
    }
  };

  const onSave = (translations) => {


    const errors = validateForm();
    if (errors.length > 0) {
      setErrors(errors);
    } else {
      setToken(values, selectedFile);

      var transToSave = [];
      for (var x = 0; x < translations.length; x++) {
        if (translationsIds.includes(translations[x].translation_id))
          transToSave.push(translations[x]);
      }
      setTokenTranslations(transToSave, 0);
      refreshTokenTable();
      onClose(true);
    }

  };

  const onSubmit = (translations) => {
    setEntityToSubmit(translations);
  };

  const handleEditDialogClose = confirm => {
    if (confirm) {
      setToken(values, selectedFile);
    }
    setEntityToEdit(undefined);
    onClose(true);
  }

  const handleSubmitDialogClose = confirm => {
    if (confirm) {
      var submit = 0;
      var y = 0;
      for (var x = 0; x < entityToSubmit.length; x++) {
        y++;
        if (entityToSubmit[x].translation === "" || entityToSubmit[x].translation === null) {
          submit = 1;
          break;
        }
        else if (y === entityToSubmit.length) {
          submit = 2;
        }
      }
      setTokenTranslations(entityToSubmit, submit);
    }
    setEntityToSubmit(undefined);
    onClose();
  }

  const handleUnsavedDialogClose = confirm => {
    if (confirm) {
      setUnSavedCloseConfirm(undefined);
      onClose(true);
    } else {
      setUnSavedCloseConfirm(undefined);
    }
  }

  const onConfirm = (event) => {
    event.preventDefault();
    if (detailValue === 0) {
      for (var x = 0; x < values.apps.length; x++) {
        if (values.apps[x].checked === true) {
          createToken(values, selectedFile);
          onClose(true);
        }
      }
    }
    else {
      var y = 0;
      for (var i = 0; i < values.apps.length; i++) {
        if (tokenApps.includes(values.apps[i].appId)) {
          if (values.apps[i].checked)
            y++;
        }
        else
          if (!values.apps[i].checked)
            y++;
      }
      if (y === values.apps.length) {
        setToken(values, selectedFile);
        onClose(true);
      }
      else
        setEntityToEdit(values);
    }
  };

  const handleAppsChange = app => {
    if (detailEdit) {
      setHaveChanges(true);
      app.checked = !app.checked;
      setValues({ ...values, apps: values.apps });
    }
  };

  const handleTranslatorChange = translator => {
    if (detailEdit) {
      setHaveChanges(true);
      translator.checked = !translator.checked;
      for (var x = 0; x < values.translators.length; x++) {
        if (values.translators[x].email !== translator.email)
          values.translators[x].checked = false;
      }
      setValues({ ...values, translators: values.translators });
    }
  };

  function checkTranslatorsDisabled() {
    if (detailEdit)
      if (checkUser(userType) === 'ADMIN')
        return false;
    return true;
  }

  const handleTranslationChange = translation => event => {
    var trans = values.translations.slice();
    var transEdited = translationsIds;
    for (var i = 0; i < trans.length; i++) {
      if (translation.translation_id === trans[i].translation_id) {
        trans[i].translation = event.target.value;
        if (!transEdited.includes(translation.translation_id))
          transEdited.push(translation.translation_id);
      }
    }
    setHaveChanges(true);
    setTranslationsIds(transEdited);
    setValues({ ...values, translations: trans });
  };

  return (
    <div className={classes.containerDashboard} >
      <Paper className={classes.workarea}>
        <div ref={toolbalHolder} className={classes.flexHeaderDetail + ' ' + (stickyToolbar ? classes.stickyToolbar : '')}>
          {canIButton('DUPLICATE', userType) && detailValue !== 0 &&
            <Button variant="contained" color="secondary" className={classes.flexButton} onClick={() => onDuplicate()}>
              <CallSplit className={classes.leftIcon} />
              {strings.buttonDuplicate}
            </Button>
          }
          <div className={classes.topButton}>
            {detailValue === 0 && <Button variant='contained' color='secondary' className={classes.flexButton} onClick={(event) => submitForm(event)}>{strings.buttonConfirm}</Button>}
          </div>
          {tokenSelected !== undefined &&
            <div>              
              {canITransation('EDIT_TRANSLATION', userType, tokenSelected.state) &&
                <Button variant="contained" color='secondary' className={classes.flexButton} onClick={() => onSave(values.translations)} >Save</Button>
              }
            </div>
          }
          {tokenSelected !== undefined &&
            <div>
              {canITransation('EDIT_TRANSLATION', userType, tokenSelected.state) &&
                <Button variant="contained" color='secondary' className={classes.flexButton} onClick={() => onSubmit(values.translations)} >Submit</Button>
              }
            </div>
          }

          <IconButton size='small' className={classes.tokenDetailBtn} onClick={() => onClose()} >
            <Close />
          </IconButton>
        </div>
        {tokenSelected !== undefined &&
          <div>
            <form ref={tokenForm} className={classes.form} autoComplete="off">

              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <div className={classes.errorsHandler}>
                    {errors.map(error => (
                      <p className={classes.errorsHandlerP} key={error}>Error: {error}</p>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <TextField fullWidth variant='outlined' margin='dense' required id='token' disabled={!detailEdit}
                    label={strings.labelName} value={values.token} onChange={handleChange('token')} />

                  <TextField fullWidth multiline variant='outlined' margin='dense' required id='default_text' disabled={!detailEdit}
                    label={strings.labelDefaultText} value={values.default_text} onChange={handleChange('default_text')} />

                  <TextField fullWidth multiline variant='outlined' margin='dense' required id='description' disabled={!detailEdit}
                    label={strings.labelDescription} value={values.description} onChange={handleChange('description')} />

                  <Grid container spacing={0} direction="row" alignItems="center">
                    <Grid item xs={10}>
                      <TextField fullWidth type='url' variant='outlined' margin='dense' id='link' disabled={!detailEdit}
                        label={strings.labelLink} value={values.link} onChange={handleChange('link')} />
                    </Grid>
                    {checkUser(userType) === 'ADMIN' && canITransation('EDIT_TRANSLATION', userType, tokenSelected.state) &&
                      <Grid item xs={2} style={{ textAlign: 'center' }}  >
                        {/* checkTranslatorsDisabled */}
                        <label htmlFor="upload-photo">
                          <input
                            style={{ display: 'none' }}
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={fileChangeHandler}
                          />
                          <Fab
                            color="secondary"
                            size="small"
                            component="span"
                            aria-label="add"
                            variant="extended"
                          >
                            <Add /> {strings.labelLinkFileUpload}
                          </Fab>
                        </label>
                      </Grid>
                    }
                    {!isFilePicked && values.link !== "" &&
                      <Grid item xs={12}>
                        <div style={{ marginTop: '10px' }}>
                          <Grid container spacing={0} direction="row" alignItems="center">
                            <Grid item xs={6}>
                              <img src={getFileFullUrl(values.link)} alt="Preview" className={classes.previewImage} />
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    }
                    <Grid item xs={12}>
                      {isFilePicked ? (
                        <div style={{ marginTop: '10px' }}>

                          <Grid container spacing={0} direction="row" alignItems="center">

                            <Grid item xs={6}>
                              <img id="previewImg" src={imagePreview} alt="Preview" className={classes.previewImage} />
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: '30px' }}>
                              <Typography className={classes.fileInfoP} variant="h6" component="h6">
                                <b>Filename:</b> {selectedFile.name}
                              </Typography>
                              <Typography className={classes.fileInfoP} variant="h6" component="h6">
                                <b>Filetype:</b> {selectedFile.type}
                              </Typography>
                              <Typography className={classes.fileInfoP} variant="h6" component="h6">
                                <b>Size:</b> {bytesToSize(selectedFile.size)}
                              </Typography>
                              {/* <p>
                                lastModifiedDate:{' '}
                                {selectedFile.lastModifiedDate.toLocaleDateString()}
                              </p> */}
                            </Grid>

                          </Grid>

                        </div>
                      ) : ''}
                    </Grid>

                  </Grid>


                </Grid>
                <Grid item xs={2}>
                  {checkUser(userType) !== 'TRANSLATOR' &&
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell className={classes.tableHeaderCell}>{strings.tableHeaderApps}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.apps.map((app, index) => (
                          <TableRow key={index}>
                            <TableCell className={classes.tableCellNoBorder} padding="checkbox" onClick={() => handleAppsChange(app)} >
                              <Checkbox checked={app.checked} color='primary' disabled={!detailEdit} />
                            </TableCell>
                            <TableCell className={classes.tableCellNoBorder}>{app.description}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  }
                </Grid>
                <Grid item xs={2}>
                  {checkUser(userType) === 'ADMIN' &&
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell className={classes.tableHeaderCell}>{strings.tableHeaderTranslators}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.translators.map((trans, index) => (
                          <TableRow key={index}>
                            <TableCell className={classes.tableCellNoBorder} padding="checkbox" onClick={() => handleTranslatorChange(trans)} >
                              <Radio checked={trans.checked} color='primary' disabled={checkTranslatorsDisabled()} />
                            </TableCell>
                            <TableCell className={classes.tableCellNoBorder}>{trans.email}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  }
                </Grid>
                {!(detailValue === 0) &&
                  <Grid item xs={12}>
                    <Typography variant='h5' className={classes.title}>{strings.translationsTitle}</Typography>
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableHeaderCell}>{strings.tableHeaderLang}</TableCell>
                          <TableCell className={classes.tableHeaderCell}>{strings.tableHeaderState}</TableCell>
                          <TableCell className={classes.tableHeaderCell}>{strings.tableHeaderTranslation}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.translations.map(translation => (
                          <TableRow key={translation.translation_id}>
                            <TableCell>{getLangDescription(translation.lang)}</TableCell>
                            <TableCell>{translation.trans_state}</TableCell>
                            <TableCell>
                              <TextField
                                fullWidth
                                multiline
                                disabled={!canITransation('EDIT_TRANSLATION', userType, tokenSelected.state)}
                                mangin='normal'
                                value={translation.translation || ''}
                                onChange={handleTranslationChange(translation)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                }
              </Grid>
            </form>
          </div>
        }
        {
          tokenSelected === undefined &&
          <CircularProgress className={classes.spinner} />
        }
      </Paper >
      {entityToDuplicate !== undefined &&
        <Dialog open={entityToDuplicate !== undefined} onClose={() => handleDuplicateDialogClose(false)}>
          <DialogTitle className={classes.title}>{strings.duplicateDialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{strings.duplicateDialogWarning.replace('{duplicates}', values.duplicates - 1)}</DialogContentText>
            <DialogContentText>{strings.duplicateDialogText}</DialogContentText>
          </DialogContent>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className={classes.tableHeaderCell}>{strings.tableHeaderApps}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entityToDuplicate.apps.map((app, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCellNoBorder} padding="checkbox" onClick={() => handleDuplicateAppsChange(app)} >
                    {!app.disabled && <Checkbox disabled={app.disabled} checked={app.checked} color='primary' />}
                  </TableCell>
                  {!app.disabled && <TableCell className={classes.tableCellNoBorder}>{app.description}</TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <DialogActions className={classes.lineButtonBox}>
            <Button className={classes.lineButton} onClick={() => handleDuplicateDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
            <Button className={classes.lineButton} onClick={() => handleDuplicateDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
          </DialogActions>
        </Dialog>
      }
      <Dialog open={entityToDuplicateError} onClose={() => handleEntityToDuplicateErrorClose(false)}>
        <DialogTitle className={classes.title}>{strings.duplicateDialogErrorTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.duplicateDialogErrorText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleEntityToDuplicateErrorClose(false)} variant='contained' color='secondary'>{strings.ok}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={entityToEdit !== undefined} onClose={() => handleEditDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.editAppsDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.editDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleEditDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleEditDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={entityToSubmit !== undefined} onClose={() => handleSubmitDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.submitDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.submitDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleSubmitDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleSubmitDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>



      <Dialog open={unSavedCloseConfirm !== undefined} onClose={() => handleUnsavedDialogClose(false)}>
        <DialogTitle className={classes.title}>{strings.exitWithoutSavingDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.exitWithoutSavingDialogText}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.lineButtonBox}>
          <Button className={classes.lineButton} onClick={() => handleUnsavedDialogClose(true)} variant='contained' color='secondary' autoFocus>{strings.yes}</Button>
          <Button className={classes.lineButton} onClick={() => handleUnsavedDialogClose(false)} variant='contained' color='secondary'>{strings.no}</Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}

const mapStateToProps = state => {
  return {
    userType: state.userType,
    tokenDetailForceRefresh: state.tokenDetailForceRefresh,
    tokenSelected: state.tokenSelected,
    detailValue: state.detailValue,
    detailEdit: state.detailEdit,
    translatorList: state.translatorList,
  }
};

export default connect(mapStateToProps, { setDetailOp, setTokenSelected })(withStyles(gStyles)(TokenDetail));