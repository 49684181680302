import React, { useEffect }  from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { setTranslatorPage, setTranslatorRowsPerPage, setTranslatorSearch, setDetailOp } from '../utils/Reducer';
import { gStyles } from '../utils/Styles';

import { withStyles } from '@material-ui/styles';
import { Table, TableHead, TableBody, TableCell, TableFooter, TablePagination, TableRow } from '@material-ui/core';
import { Paper, CircularProgress, InputBase, Button, IconButton, Tooltip } from '@material-ui/core';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, } from '@material-ui/core';
import { Search, AddCircle, Delete, Edit, Close, Done } from '@material-ui/icons';

import TablePaginationActions from '../components/TablePaginationActions';
import { canIButton } from '../utils/Utils';
import { getTranslators, deleteTranslator, setTranslatorState } from './TranslatorRest';

let strings=new LocalizedStrings({
	en:{
    buttonAdd:'Add new translator',

    stateCombo: 'State',

    toolTipDelete:'Delete translator',
    toolTipEdit:'Edit translator',
    toolTipActi:'Activate translator',
    toolTipDes:'Deactivate translator',
    tableHeaderEmail: 'Email',
    tableHeaderPass: 'Password',
    tableHeaderState: 'State',
    tableHeaderExport: 'Export',
    searchPlaceHolder:'search',

    deleteDialogTitle: 'Delete Confirmation',
    deleteDialogText: 'Are you sure that you want to permanently delete translator \'{entity}\'?',
    yes: 'Yes',
    no: 'No',
	}
});

function TranslatorTable( props ) { 

  const { classes } = props

  const [ entityToDelete, setEntityToDelete ] = React.useState( undefined );


  const { userType } = props;
  const { translatorTableForceRefresh } = props;
  const { translatorPage, setTranslatorPage } = props;
  const { translatorRowsPerPage, setTranslatorRowsPerPage } = props;
  const { translatorSearch, setTranslatorSearch } = props;
  const { translatorList } = props;
  
  const { setDetailOp } = props;
  
  const [ search, setSearch ] = React.useState( translatorSearch );

  const page = translatorPage;
  const rowsPerPage = translatorRowsPerPage;

  useEffect(
    ( ) => {
        getTranslators( page * rowsPerPage, rowsPerPage );
    },
    [ page, rowsPerPage, translatorSearch, translatorTableForceRefresh]
  );


  const handleChangePage = ( event, newPage ) => { 
    setTranslatorPage( newPage );
  }

  const handleChangeRowsPerPage = event => { 
    setTranslatorRowsPerPage( parseInt(event.target.value, 10) );
  }

  const searchKeyPress = event => {
    if ( event.charCode === 13 ) {//ENTER key pressed
      event.preventDefault();
      setTranslatorSearch( search );
    }
  }

  const onAdd = () => {
    setDetailOp( { type:'translator', value: '' } );
  }

  const onEdit = ( translator ) => {
    setDetailOp( { type:'translator', value: translator.email } );
  }

  const onActivate = ( translator ) => {
    setTranslatorState( translator.email, 0 );
  }

  const onDeactivate = ( translator ) => {
    setTranslatorState( translator.email, 1 );
  }

  const onDelete = translator => {
    setEntityToDelete( translator );
  }


  const handleDeleteDialogClose = confirm => {
    if ( confirm )
      deleteTranslator( entityToDelete );
    setEntityToDelete( undefined );
  }

  return (
    <div className={ classes.containerDashboard } >
      <Paper className={ classes.workarea }>
        <div className={ classes.flexHeader }>
          { canIButton('ADD_TRANSLATOR', userType) &&
            <Button variant="contained" color="secondary" className={ classes.flexButton } onClick={ ( ) => onAdd( ) }>
              <AddCircle className={ classes.leftIcon } />
              { strings.buttonAdd }
            </Button>
          }
          <div className={ classes.flexExpand }/>
          <Paper className={ classes.searchPaper }>
            <InputBase
              className={ classes.searchInput }
              placeholder={ strings.searchPlaceHolder }
              inputProps={ { 'aria-label': 'Search Text' } }
              value={ search }
              onChange={ ( event ) => setSearch( event.target.value ) }
              onKeyPress={ ( event ) => searchKeyPress( event ) } />
            <IconButton aria-label='Search' size='small' onClick={ () => setTranslatorSearch( search ) }>
              <Search/>
            </IconButton>
          </Paper>
        </div>       
        <div className={ classes.tableArea } >
          { translatorList !== undefined &&
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell className={ classes.tableHeaderCell }>{ strings.tableHeaderEmail }</TableCell>
                  <TableCell className={ classes.tableHeaderCell }>{ strings.tableHeaderPass }</TableCell>
                  <TableCell className={ classes.tableHeaderCell }>{ strings.tableHeaderState }</TableCell>
                  <TableCell className={ classes.tableHeaderCell }>{ strings.tableHeaderExport }</TableCell>
                  <TableCell /><TableCell /><TableCell /><TableCell /><TableCell /><TableCell /><TableCell /><TableCell /><TableCell /><TableCell />
                  <TableCell colSpan={ 99 }/>
                </TableRow>
              </TableHead>
              <TableBody>
                { translatorList.items.map( translator => {
                  return (
                  <TableRow key={ translator.email }>
                    {  <TableCell style={{ width:'30%' }}>{ translator.email }</TableCell> }
                    {  <TableCell style={{ width:'30%' }}>{ translator.password }</TableCell> }
                    {  <TableCell style={{ width:'30%' }}>{ translator.state }</TableCell> }
                    {  <TableCell style={{ width:'30%' }}>{ translator.export }</TableCell> }
                    <TableCell /><TableCell /><TableCell /><TableCell /><TableCell /><TableCell /><TableCell /><TableCell /><TableCell />                    
                    { (translator.state === 'INACTIVE') &&
                      <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipActi }>
                          <IconButton size='small' onClick={ () => onActivate( translator ) } >
                          <Done/>
                          </IconButton>
                      </Tooltip>
                      </TableCell>
                    }                
                    { (translator.state === 'ACTIVE') &&
                      <TableCell className={ classes.tableButton } >
                      <Tooltip title={ strings.toolTipDes }>
                          <IconButton size='small' onClick={ () => onDeactivate( translator ) } >
                          <Close/>
                          </IconButton>
                      </Tooltip>
                      </TableCell>
                    }                    
                    <TableCell className={ classes.tableButton } >
                    <Tooltip title={ strings.toolTipEdit }>
                        <IconButton size='small' onClick={ () => onEdit( translator ) } >
                        <Edit/>
                        </IconButton>
                    </Tooltip>
                    </TableCell>
                    <TableCell className={ classes.tableButton } >
                    <Tooltip title={ strings.toolTipDelete }>
                        <IconButton size='small' onClick={ ( ) => onDelete( translator ) } >
                        <Delete/> 
                        </IconButton>
                    </Tooltip>
                    </TableCell>                   
                  </TableRow>
                ) } ) }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination rowsPerPageOptions={ [ 10, 20, 40 ] } colSpan={ 99 } count={ translatorList.count } rowsPerPage={ rowsPerPage } page={ page }
                    SelectProps={ { inputProps: { 'aria-label': 'Rows per page' }, native: true, } }
                    onChangePage={ handleChangePage }
                    onChangeRowsPerPage={ handleChangeRowsPerPage }
                    ActionsComponent={ TablePaginationActions }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          }
          { translatorList === undefined &&
              <CircularProgress className={ classes.spinner }/>
          }
        </div>
      </Paper>
      <Dialog open={ entityToDelete !== undefined } onClose={ ( ) => handleDeleteDialogClose( false ) }>
        <DialogTitle className={ classes.title }>{ strings.deleteDialogTitle }</DialogTitle>
        <DialogContent>
          <DialogContentText>{ strings.deleteDialogText.replace( '{entity}', entityToDelete !== undefined ? entityToDelete.email : '' ) }</DialogContentText>
        </DialogContent>
        <DialogActions className={ classes.lineButtonBox }>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( true ) } variant='contained' color='secondary' autoFocus>{ strings.yes }</Button>
          <Button className={ classes.lineButton } onClick={ ( ) => handleDeleteDialogClose( false ) } variant='contained' color='secondary'>{ strings.no }</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
 }

const mapStateToProps = state => { 
  return {
    userType: state.userType,
    translatorTableForceRefresh: state.translatorTableForceRefresh,
    translatorPage: state.translatorPage,
    translatorRowsPerPage: state.translatorRowsPerPage,
    translatorSearch: state.translatorSearch,
    translatorList: state.translatorList,
  }
};

export default connect( mapStateToProps, { setTranslatorPage, setTranslatorRowsPerPage, setTranslatorSearch, setDetailOp } )( withStyles( gStyles )( TranslatorTable ) );